// src/analytics/AnalyticsProvider.tsx
import React, {
  useEffect,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useCallback,
} from 'react'
import { useLocation } from 'react-router-dom'
import { H } from 'highlight.run'
import { useUser, useClerk } from '@clerk/clerk-react'
import { UTMManager } from '../utils/utmManager'
import { debounce } from 'lodash'

type AnalyticsContextType = {
  trackEvent: (eventName: string, properties?: Record<string, any>) => void
  debouncedTrackEvent: (
    eventName: string,
    properties?: Record<string, any>
  ) => void
}

const AnalyticsContext = createContext<AnalyticsContextType>({
  trackEvent: () => {},
  debouncedTrackEvent: () => {},
})

export const useAnalytics = () => useContext(AnalyticsContext)

type ProviderProps = {
  children: ReactNode
}

export const AnalyticsProvider: React.FC<ProviderProps> = ({ children }) => {
  const location = useLocation()
  const { user } = useUser()
  const { session } = useClerk()

  // Initialize analytics tools
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      H.init('kevq3llg', {
        serviceName: 'voltpro-app',
        tracingOrigins: [
          'localhost',
          'volt-app-staging.onrender.com/graphql',
          'volt-app.onrender.com/graphql',
          'app.voltpro.com/graphql',
        ],
        networkRecording: {
          enabled: true,
          recordHeadersAndBody: true,
          urlBlocklist: [
            'https://www.googleapis.com/identitytoolkit',
            'https://securetoken.googleapis.com',
          ],
        },
      })
    }

    // Initialize dataLayer for GTM
    window.dataLayer = window.dataLayer || []

    // Capture UTM parameters on initial load
    UTMManager.captureParams()
  }, [])

  // Track page views
  useEffect(() => {
    const utmParams = UTMManager.captureParams()

    window.dataLayer.push({
      event: 'page_view',
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
      userId: user?.id,
      sessionId: session?.id,
      ...utmParams,
    })
  }, [location, user, session])

  // General event tracking function
  const trackEvent = useCallback(
    (eventName: string, properties: Record<string, any> = {}) => {
      const utmParams = UTMManager.captureParams()

      /**
       * Additional property notes
       *
       * Estimate Tag: create_estimate
       * Parameters:
       *   estimateId
       *   estimate_amount
       *   estimate_currency
       *   estimate_item_count
       *
       * Membership Tag: membership_purchased
       * Parameters:
       *   membership_plan
       *   membership_amount
       *   membership_currency
       *   membership_cycle
       */
      window.dataLayer.push({
        event: eventName,
        userId: user?.id,
        sessionId: session?.id,
        ...utmParams,
        ...properties,
      })

      if (process.env.NODE_ENV === 'development') {
        console.log('trackEvent', {
          event: eventName,
          userId: user?.id,
          sessionId: session?.id,
          ...utmParams,
          ...properties,
        })
      }
    },
    [user, session]
  )

  // Create debounced version to be used in events fired
  // from components which possibly re-render. This is a little
  // hacky as we shouldn't need to track events in use effects.
  const debouncedTrackEvent = useMemo(
    () =>
      debounce((eventName: string, properties: Record<string, any> = {}) => {
        trackEvent(eventName, properties)
      }, 500),
    [trackEvent]
  )

  // Clean up debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedTrackEvent.cancel()
    }
  }, [debouncedTrackEvent])

  const contextValue = useMemo(
    () => ({ trackEvent, debouncedTrackEvent }),
    [trackEvent, debouncedTrackEvent]
  )

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
    </AnalyticsContext.Provider>
  )
}
