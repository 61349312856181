import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const MaterialFormSectionRowTitleStyled = styled.div<{
  viewOnly?: boolean
}>`
  margin: 20px 0 10px 0;
  padding: 10px 0 10px 10px;
  border-left: ${({ viewOnly, theme }) =>
    viewOnly ? `5px solid ${theme.colors.accent}` : 'none'};
  background-color: ${({ viewOnly, theme }) =>
    viewOnly ? `${theme.colors.background}` : 'none'};

  .ant-divider {
    margin: ${rem(8)} 0;
  }
`
