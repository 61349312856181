import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  SelectProps,
  Select,
  Divider,
  Row,
  Col,
  Button,
  Typography,
} from 'antd'
import React, { useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'

import {
  useGetTaxesQuery,
  TaxDetailFragment,
} from '../../graphql/_generated-hooks'

import { TaxInputManagerStyled } from './styles'
import { TaxSelect } from '../TaxSelect'

export interface TaxInputManagerProps<ValueType = any> {
  taxOptions?: TaxDetailFragment[]
  appliedTaxes?: TaxDetailFragment[]
  createButtonVisible?: boolean
  onApplyTax?: (appliedTax: TaxDetailFragment) => void
  onAddClick?: () => void
}

export const TaxInputManager = <
  ValueType extends {
    key?: string
    label: React.ReactNode
    value: string | number
  } = any
>({
  taxOptions = [],
  appliedTaxes = [],
  onApplyTax = () => {},
  onAddClick = () => {},
  ...props
}: TaxInputManagerProps<ValueType>) => {
  const [selectedTaxId, setSelectedTaxId] = React.useState<any>(null)
  const [allTaxes, setAllTaxes] = React.useState<TaxDetailFragment[]>([])
  const [availableTaxes, setAvailableTaxes] = React.useState<
    TaxDetailFragment[]
  >([])

  React.useEffect(() => {
    const appliedTaxIds = appliedTaxes.map((tax) => tax?.id)
    // console.log(
    //   'diff',
    //   taxOptions,
    //   appliedTaxes,
    //   taxOptions.filter((tax) => !appliedTaxIds.find((id) => id === tax?.id))
    // )
    setAllTaxes(taxOptions)
    setAvailableTaxes(
      taxOptions.filter((tax) => !appliedTaxIds.find((id) => id === tax?.id))
    )
  }, [taxOptions, appliedTaxes])

  const handleApplyTax = () => {
    if (!selectedTaxId) return
    const taxObj = allTaxes?.find((tax) => tax?.id === selectedTaxId)

    if (taxObj) {
      onApplyTax(taxObj)
      setSelectedTaxId(null)
    }
  }

  return (
    <TaxInputManagerStyled>
      <Row gutter={16}>
        <Col span={16}>
          <TaxSelect
            // @ts-ignore
            taxOptions={availableTaxes.map((tax) => ({
              value: tax?.id,
              label: `${tax?.display_name} (${tax?.percentage}%)`,
            }))}
            onSelect={(value: any) => {
              setSelectedTaxId(value)
            }}
            onAddClick={onAddClick}
            placeholder='Select Tax'
            {...props}
          />
        </Col>
        <Col span={8}>
          <Button
            style={{ display: 'block', width: '100%' }}
            onClick={handleApplyTax}
            disabled={!availableTaxes.length}
            data-testid='tax-apply-button'
          >
            Apply
          </Button>
        </Col>
      </Row>
    </TaxInputManagerStyled>
  )
}

TaxInputManager.displayName = 'TaxInputManager'
