import { LoadingOutlined } from '@ant-design/icons'
import { Typography, Space, Row, Col } from 'antd'
import * as React from 'react'

import { VoltProIcon } from '../VoltProIcon'
import { formatMoney } from '../../utils'
import { MaterialSelectOptionRenderStyled } from './styles'

export interface MaterialSelectOptionRenderProps {
  title: string
  price?: number
  subtitle?: string
  globalCatalog?: boolean
}

export const MaterialSelectOptionRender = ({
  title = 'N/A',
  price,
  subtitle,
  globalCatalog,
}: MaterialSelectOptionRenderProps) => {
  return (
    <MaterialSelectOptionRenderStyled>
      <Typography.Text ellipsis>
        <Space>
          {globalCatalog && (
            <span style={{ opacity: '0.35' }}>
              <VoltProIcon tooltipTitle='VoltPro global price catalog' />
            </span>
          )}
          {title}
        </Space>
      </Typography.Text>

      <Row
        style={{
          marginTop: '5px',
        }}
        wrap={false} // Prevent wrapping to new line
      >
        <Col
          flex='1 1 auto'
          style={{
            minWidth: 0,
            paddingRight: '16px', // Add padding to maintain space between description and price
          }}
        >
          <Typography.Text
            type='secondary'
            ellipsis
            style={{
              display: 'block',
            }}
          >
            {subtitle || (
              <span style={{ fontStyle: 'italic', opacity: 0.5 }}>
                No description
              </span>
            )}
          </Typography.Text>
        </Col>
        {price && (
          <Col flex='0 0 auto'>
            {' '}
            {/* Fixed width column that won't shrink */}
            <Typography.Text
              type='secondary'
              style={{
                whiteSpace: 'nowrap', // Prevent price from wrapping
              }}
            >
              {formatMoney(price)}
            </Typography.Text>
          </Col>
        )}
      </Row>
    </MaterialSelectOptionRenderStyled>
  )
}

MaterialSelectOptionRender.displayName = 'MaterialSelectOptionRender'
