import { FileText, List, Layers, Users, Lock } from 'react-feather'
import type { MenuProps } from 'antd'
import { Space, Menu, Tooltip, Tag } from 'antd'
import * as React from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'

import logo from './logo.png'

import { NavigationStyled } from './styles'

export interface NavigationProps {
  onboardingStepRef?: any
  logo?: React.ReactNode
  isFreePlan?: boolean
  callToAction?: React.ReactNode
  settingsItem?: React.ReactNode
}

export const Navigation = React.forwardRef(
  ({ ...props }: NavigationProps, onboardingStepRef: any) => {
    const [current, setCurrent] = React.useState<string[]>([])
    const navigate = useNavigate()
    const location = useLocation()

    React.useEffect(() => {
      if (location.pathname) {
        const snippets = location.pathname.split('/').filter((i) => i)
        if (snippets.length > 0) {
          setCurrent(snippets)
        }
      }
    }, [location])

    const onClick: MenuProps['onClick'] = (e) => {
      setCurrent([e.key])
      navigate(`/${e.key}`, { replace: true })
    }

    const items: MenuProps['items'] = [
      {
        label: 'Estimates',
        key: 'estimates',
        icon: <FileText size={16} />,
      },
      {
        label: 'Materials',
        key: 'materials',
        icon: <List size={16} />,
      },
      {
        label: 'Customers',
        key: 'customers',
        icon: <Users size={16} />,
      },
      {
        label: 'Assemblies',
        key: 'assemblies',
        icon: <Layers size={16} />,
        // icon: props.isFreePlan ? <Lock size={16} /> : <Layers size={16} />,
        // disabled: props.isFreePlan,
        // label: (
        //   <>
        //     {props.isFreePlan ? (
        //       <Tooltip
        //         title='Templates are a premium feature'
        //         placement='right'
        //       >
        //         Templates
        //       </Tooltip>
        //     ) : (
        //       'Templates'
        //     )}
        //   </>
        // ),
      },
    ]

    return (
      <NavigationStyled>
        <NavLink to='/'>{props.logo}</NavLink>
        <div ref={onboardingStepRef}>
          <Menu
            mode='inline'
            onClick={onClick}
            selectedKeys={current}
            defaultOpenKeys={['directory']}
            items={items}
          />
        </div>
        {props.settingsItem && (
          <div
            style={{
              marginTop: 'auto',
              paddingTop: '0.5em',
              paddingBottom: '1.5em',
              textAlign: 'center',
            }}
          >
            {props.callToAction && props.callToAction}
            <NavLink
              to='/settings'
              onClick={() => setCurrent([])}
              style={{ justifyContent: 'center' }}
            >
              {props.settingsItem}
            </NavLink>
          </div>
        )}
      </NavigationStyled>
    )
  }
)

Navigation.displayName = 'Navigation'
