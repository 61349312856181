import * as React from 'react'
import { Tooltip } from 'antd'

import { theme } from '../../styles/themes/default'
import { VoltProIconStyled } from './styles'

const vpIcon = `${process.env.PUBLIC_URL}/logo_medium.png`

export interface VoltProIconProps {
  tooltipTitle?: string
  style?: React.CSSProperties
}

export const VoltProIcon = ({ tooltipTitle, style }: VoltProIconProps) => {
  return (
    <VoltProIconStyled>
      <Tooltip title={tooltipTitle}>
        <div
          style={{
            width: 20,
            height: 12,
            display: 'flex',
            verticalAlign: 'top',
            ...style,
          }}
        >
          <img
            style={{ width: '100%', height: '100%' }}
            src={vpIcon}
            alt='VoltPro item'
          />
        </div>
      </Tooltip>
    </VoltProIconStyled>
  )
}

VoltProIcon.displayName = 'VoltProIcon'
