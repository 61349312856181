// src/utils/utmManager.ts

export interface UTMParams {
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_content?: string
  utm_term?: string
  first_touch_timestamp?: string
}

const UTM_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
]

export const UTMManager = {
  // Capture UTM parameters from URL or localStorage
  captureParams(): UTMParams {
    const params = new URLSearchParams(window.location.search)
    const utmParams: UTMParams = {}

    let hasUtmParams = false
    UTM_PARAMS.forEach((param) => {
      // Try URL first
      if (params.has(param)) {
        utmParams[param as keyof UTMParams] = params.get(param) || undefined
        localStorage.setItem(param, params.get(param) || '')
        hasUtmParams = true
      } else {
        // Try localStorage if not in URL
        const storedValue = localStorage.getItem(param)
        if (storedValue) {
          utmParams[param as keyof UTMParams] = storedValue
          hasUtmParams = true
        }
      }
    })

    // Store first touch timestamp if new UTM parameters found
    if (hasUtmParams && !localStorage.getItem('first_touch_timestamp')) {
      const timestamp = Date.now().toString()
      localStorage.setItem('first_touch_timestamp', timestamp)
      utmParams.first_touch_timestamp = timestamp
    } else {
      utmParams.first_touch_timestamp =
        localStorage.getItem('first_touch_timestamp') || undefined
    }

    return utmParams
  },

  // Get UTM parameters as a query string
  getParamsAsString(): string {
    const utmParams = this.captureParams()
    const params = new URLSearchParams()

    Object.entries(utmParams).forEach(([key, value]) => {
      if (value) params.append(key, value)
    })

    return params.toString()
  },

  // Create a URL with UTM parameters
  appendParamsToUrl(baseUrl: string): string {
    const utmString = this.getParamsAsString()
    if (!utmString) return baseUrl

    const separator = baseUrl.includes('?') ? '&' : '?'
    return `${baseUrl}${separator}${utmString}`
  },
}
