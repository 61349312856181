import { Zap } from 'react-feather'
import { PlusOutlined } from '@ant-design/icons'
import {
  ConfigProvider,
  Table,
  Typography,
  Tag,
  Popover,
  Space,
  Popconfirm,
  Button,
} from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import * as React from 'react'

import { ProductDetailFragment } from '../../graphql/_generated-hooks'
import { GlobalMaterialsTableStyled } from './styles'
import { PriceAgeTag } from '../PriceAgeTag'
import { DemoItemTag } from '../DemoItemTag'
import { MaterialNameDescription } from '../MaterialNameDescription'
import { ServiceItemBadge } from '../ServiceItemBadge'
import { formatDate, formatMoney } from '../../utils'
import { theme } from '../../styles/themes/default'

const { Title, Paragraph } = Typography

interface ExtendedProductDetailFragment extends ProductDetailFragment {
  latest_price: any
}

export interface GlobalMaterialsTableProps {
  data: any // TODO: ProductDetailFragment[]
  tableProps?: TableProps<ExtendedProductDetailFragment>
  addToListLoading?: boolean
  onMaterialClick?: (user: ExtendedProductDetailFragment, event: any) => void
  onMaterialDelete?: (user: ExtendedProductDetailFragment) => void
  onChange?: (pagination: any, filters: any, sorter: any, extra: any) => void
  onAddToList?: (user: ExtendedProductDetailFragment) => void
}

export const GlobalMaterialsTable = ({
  data = [],
  tableProps: { loading } = {},
  addToListLoading,
  onMaterialClick = () => undefined,
  onMaterialDelete = () => undefined,
  onAddToList = () => undefined,
  onChange = () => undefined,
}: GlobalMaterialsTableProps) => {
  const [loadingRows, setLoadingRows] = React.useState<Record<string, boolean>>(
    {}
  )

  // TODO move to a shared component
  const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center', margin: '3em 0' }}>
      <Typography.Text type='secondary' italic>
        No materials found
      </Typography.Text>
    </div>
  )

  const handleAddToList = async (row: ExtendedProductDetailFragment) => {
    setLoadingRows((prev) => ({ ...prev, [row.id]: true }))
    try {
      await onAddToList(row)
    } finally {
      setLoadingRows((prev) => ({ ...prev, [row.id]: false }))
    }
  }

  const tableColumns: ColumnsType<ExtendedProductDetailFragment> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: '75%',
      render: (_, { id, name, description, sku }) => (
        <MaterialNameDescription
          name={name}
          description={description}
          copyName={false}
        />
      ),
    },
    // {
    //   title: 'Material name',
    //   dataIndex: 'name',
    //   key: 'name',
    //   sorter: true,
    // },
    // {
    //   title: 'SKU',
    //   dataIndex: 'sku',
    //   key: 'sku',
    //   render: (_, { id, name, sku }) => (
    //     <Typography>
    //       <Paragraph
    //         style={{ marginBottom: 0 }}
    //         ellipsis
    //         copyable={sku ? true : false}
    //       >
    //         {sku || '-'}
    //       </Paragraph>
    //     </Typography>
    //   ),
    // },
    // {
    //   title: 'Categories',
    //   dataIndex: 'categories',
    //   ellipsis: true,
    //   align: 'center',
    //   render: (_, { categories }) => {
    //     if (!categories || !categories.length) return ' - '

    //     const categoryNames = categories?.map((category) => category?.name)
    //     return (
    //       <>
    //         <Popover
    //           placement='topLeft'
    //           content={() => {
    //             return (
    //               <Space direction='vertical'>
    //                 {categoryNames.map((categoryName, $index) => (
    //                   <span key={$index}>{categoryName || ''}</span>
    //                 ))}
    //               </Space>
    //             )
    //           }}
    //           title='Categories'
    //         >
    //           {categoryNames.map((categoryName, $index) => (
    //             <Tag key={$index}>{categoryName || ''}</Tag>
    //           ))}
    //         </Popover>
    //       </>
    //     )
    //   },
    // },
    {
      title: 'Price',
      dataIndex: 'prices',
      key: 'prices',
      align: 'right',
      render: (_, { id, name, prices, unit_uom }) => {
        const unit_amount = prices?.[0]?.unit_amount

        if (unit_amount) {
          return (
            <Typography>
              <Paragraph style={{ marginBottom: 0 }} ellipsis>
                {formatMoney(unit_amount)}
                {unit_uom && ` / ${unit_uom}`}
              </Paragraph>
            </Typography>
          )
        } else {
          return (
            <Typography>
              <Paragraph style={{ marginBottom: 0 }} italic ellipsis>
                No price data
              </Paragraph>
            </Typography>
          )
        }
      },
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 100,
      // eslint-disable-next-line react/display-name
      render: (data) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Space>
              {/* <Popconfirm
                icon={
                  <Zap
                    size={16}
                    style={{
                      color: theme.colors.accent,
                      height: '22px',
                      marginRight: '5px',
                    }}
                  />
                }
                title='Add item to your material catalog?'
                okText='Add'
                cancelText='No'
                onConfirm={() => data.onAddToList(data)}
              >
              </Popconfirm> */}
              <Button
                shape='circle'
                icon={<PlusOutlined />}
                loading={loadingRows[data.id]}
                onClick={() => handleAddToList(data)}
              />
            </Space>
          </div>
        )
      },
    },
  ]

  return (
    <GlobalMaterialsTableStyled>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table<ExtendedProductDetailFragment>
          dataSource={data?.map((product: any) => ({
            key: product.id,
            onDelete: onMaterialDelete,
            onAddToList,
            ...product,
          }))}
          loading={loading}
          columns={tableColumns}
          onRow={(row, rowIndex) => {
            return {
              onClick: (event) => {
                onMaterialClick(row, event)
              },
            }
          }}
          onChange={(
            pagination: any,
            filters: any,
            sorter: any,
            extra: any
          ) => {
            onChange(pagination, filters, sorter, extra)
          }}
          pagination={false}
          showHeader={false}
          size='small'
        />
      </ConfigProvider>
    </GlobalMaterialsTableStyled>
  )
}

GlobalMaterialsTable.displayName = 'GlobalMaterialsTable'
