import { CheckCircleFilled } from '@ant-design/icons'
import { DrawerForm, ProForm, ProFormText } from '@ant-design/pro-components'
import {
  useCreateProductMutation,
  useCreatePriceMutation,
  useUpdateUploadedDocumentMutation,
  ProductTypeFieldInput,
} from '../../graphql/_generated-hooks'
import { Button, Row, Col, Form, message } from 'antd'
import React, { useMemo, useRef, useState, memo } from 'react'
import debounce from 'lodash/debounce'
import { useAppStore } from '../../stores/appStore'

import { MaterialBulkCreateItemTable } from './MaterialBulkCreateItemTable'
import { DistributorSearchSelect } from '../DistributorSearchSelect'
import { MaterialBulkCreateOverlayStyled } from './styles'
import {
  formatVendorName,
  roundMoney,
  formatCents,
  trucateNumber,
  setApiFormErrors,
} from '../../utils'
import { PDFViewer } from '../PDFViewer'

const DRAWER_WIDTH = 800
export interface MaterialBulkCreateOverlayProps {
  open?: boolean
  fileData: any
  fileUrl?: string
  fileMimeType?: string
  newDistributor?: any
  onClose?: () => void
  onCreateNewDistributor?: () => void
}
export interface MaterialServiceFormSchema {
  invoice_number: string
  purchase_order_number: string
  // distributor: DistributorSelectOrCreateSchema
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const calculateDocPosition = (drawerWidth: number) => {
  const viewportWidth = window.innerWidth
  const viewportHeight = window.innerHeight
  const remainingWidth = viewportWidth - drawerWidth
  const xPadding = remainingWidth * 0.1
  const yPadding = remainingWidth * 0.1
  const docMaxWidth = remainingWidth - xPadding * 2
  const docMaxHeight = viewportHeight - yPadding * 2

  return {
    left: (remainingWidth - docMaxWidth) / 2,
    width: docMaxWidth,
    height: docMaxHeight,
  }
}

export const MaterialBulkCreateOverlay = ({
  open,
  fileData = { items: [] },
  fileUrl,
  fileMimeType,
  newDistributor,
  onClose = () => {},
  onCreateNewDistributor = () => {},
}: MaterialBulkCreateOverlayProps) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm<any>()
  const [items, setItems] = React.useState(fileData?.items) || []
  const [disableSubmit, setDisableSubmit] = React.useState(false)
  const [submitLoading, setSubmitLoading] = React.useState(false)
  const [submitComplete, setSubmitComplete] = React.useState(false)

  const [createMaterial, { data, loading: createProductLoading, error }] =
    useCreateProductMutation()
  const [createPrice, { loading: createPriceLoading }] =
    useCreatePriceMutation()
  const [updateUploadedDocument, { loading: updateDocumentMutationLoading }] =
    useUpdateUploadedDocumentMutation()

  React.useEffect(() => {
    if (newDistributor && newDistributor.id) {
      form.setFieldValue('distributor', {
        value: newDistributor.id,
        label: newDistributor.name,
      })
    }

    if (fileData?.matchedExistingDistributor) {
      form.setFieldValue('distributor', {
        value: fileData.matchedExistingDistributor?.id,
        label: fileData.matchedExistingDistributor?.name,
      })
    }

    if (fileData?.items) {
      const formattedItems: any = fileData.items.map(
        (item: any, $index: number) => {
          return {
            ...item,
            key: $index,
            formatted_name: item?.properties?.description?.value,
            formatted_unit_amount: trucateNumber(
              formatCents(item?.properties?.unitPrice?.value?.amount)
            ),
            formatted_unit: item?.properties?.unit?.value || 'E',
            formatted_date: fileData?.date,
            formatted_description: item.matchedProduct?.description,
          }
        }
      )
      setItems(formattedItems)
    }

    form.setFieldValue('invoice_number', fileData?.invoice_number)
    form.setFieldValue('purchase_order_number', fileData?.purchase_order_number)
  }, [fileData, newDistributor])

  const handleSkipRow = (rowIndex: number | undefined, skip?: boolean) => {
    const newItems = items.map((item: any, index: number) => {
      if (index === rowIndex) {
        return { ...item, skipped: skip }
      }
      return item
    })

    setItems(newItems)
    setSubmitComplete(false)
  }
  const handleUpdateUploadedDocument = async (
    invoice_number: string,
    purchase_order_number: string
  ) => {
    try {
      const updatedUploadedDocument = await updateUploadedDocument({
        variables: {
          id: fileData.id,
          input: {
            ...(invoice_number && { invoice_number }),
            ...(purchase_order_number && { purchase_order_number }),
          },
        },
      })
    } catch (error) {
      console.error(`Error updating uploaded document: ${error}`)
    }
  }

  const handleRowEdit = (row: any, errors?: any) => {
    if (errors) {
      return setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
      setSubmitComplete(false)
    }

    const newData = [...items]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row,
    })

    setItems(newData)
  }
  const handleOnFormChange = useMemo(() => {
    return debounce(() => setSubmitComplete(false), 300)
  }, [])
  const handleOnFinish = async (values: any) => {
    if (fileData.IS_DEMO) return

    setSubmitLoading(true)

    const { invoice_number, purchase_order_number, distributor } = values

    if (invoice_number || purchase_order_number) {
      await handleUpdateUploadedDocument(invoice_number, purchase_order_number)
    }

    let currentItems = [...items]
    let progressMetrics = { success: 0, error: 0 }
    for (const [index, item] of items.entries()) {
      if (item.skipped || item.mutationResponse?.created) continue

      try {
        if (item.matchedProduct && item.matchedProduct.id) {
          await createPrice({
            variables: {
              input: {
                productId: item.matchedProduct.id,
                unit_amount: trucateNumber(item.formatted_unit_amount),
                uploadedDocumentId: fileData.id,
                distributorId: distributor?.value,
                ...(item.formatted_date && {
                  invoicedAt: new Date(item.formatted_date).toUTCString(),
                }),
              },
            },
          })
        } else {
          await createMaterial({
            variables: {
              input: {
                name: item.formatted_name,
                unit_amount: trucateNumber(item.formatted_unit_amount),
                unit_uom: item.formatted_unit,
                distributorId: distributor?.value,
                uploadedDocumentId: fileData.id,
                type: ProductTypeFieldInput.Good,
                ...(item.formatted_date && {
                  invoicedAt: new Date(item.formatted_date).toUTCString(),
                }),
                ...(item.formatted_description && {
                  description: item.formatted_description,
                }),
              },
            },
          })
        }

        currentItems.splice(index, 1, {
          ...item,
          mutationResponse: { created: true },
        })
        progressMetrics.success++
      } catch (error: any) {
        currentItems.splice(index, 1, {
          ...item,
          mutationResponse: {
            created: false,
            error: true,
            errorMessage: error.message,
          },
        })
        progressMetrics.error++
      }

      await delay(300)
    }

    setItems(currentItems)

    setSubmitLoading(false)
    setSubmitComplete(true) // This is used to close the drawer

    if (progressMetrics.success > 0) {
      messageApi.success(
        `Successfully saved ${progressMetrics.success} item${
          progressMetrics.success > 1 ? 's' : ''
        }`
      )
    }
  }

  return (
    <MaterialBulkCreateOverlayStyled>
      {contextHolder}
      <DrawerForm
        title={'Add Materials'}
        form={form}
        open={open}
        drawerProps={{
          getContainer: false, // To supress form render warning(https://stackoverflow.com/questions/61056421/warning-instance-created-by-useform-is-not-connect-to-any-form-element)
          destroyOnClose: true,
          maskClosable: false,
          onClose: () => {
            setSubmitComplete(false)
            onClose()
          },
          width: DRAWER_WIDTH,
        }}
        onFinish={handleOnFinish}
        onChange={handleOnFormChange}
        submitter={{
          searchConfig: {
            submitText: 'Submit',
            resetText: 'Cancel',
          },
          submitButtonProps: {
            loading: submitLoading,
            disabled: disableSubmit,
            id: 'bulk-material-create-submit-button',
          },
          resetButtonProps: {
            id: 'bulk-material-create-cancel-button',
          },
          render: (props, [reset, submit]) => {
            return [
              reset,
              submitComplete ? (
                <Button
                  type='primary'
                  style={{ float: 'right' }}
                  key='done'
                  onClick={() => {
                    setSubmitComplete(false)
                    onClose()
                  }}
                >
                  Done
                </Button>
              ) : (
                submit
              ),
            ]
          },
        }}
      >
        <Row gutter={24}>
          <Col span={8}>
            <ProForm.Group>
              <ProFormText
                name='invoice_number'
                width='xl'
                rules={[
                  {
                    required: false,
                  },
                ]}
                label='Invoice Number'
                placeholder='INV_000000'
              />
            </ProForm.Group>
          </Col>
          <Col span={8}>
            <ProForm.Group>
              <ProFormText
                name='purchase_order_number'
                width='xl'
                rules={[
                  {
                    required: false,
                  },
                ]}
                label='Purchase Order Number'
                placeholder='PO_000000'
              />
            </ProForm.Group>
          </Col>
          <Col span={8}>
            <DistributorSearchSelect
              formItemProps={{
                label: 'Vendor',
                name: 'distributor',
                rules: [
                  {
                    required: true,
                  },
                ],
              }}
              onAddClick={onCreateNewDistributor}
              recentlyCreatedDistributor={
                newDistributor
                  ? [{ value: newDistributor.id, label: newDistributor.name }]
                  : []
              }
            />
          </Col>
        </Row>

        <MaterialBulkCreateItemTable
          items={items}
          loading={submitLoading}
          onRowEdit={handleRowEdit}
          onToggleSkipRow={handleSkipRow}
        />

        {fileUrl && (
          <div
            style={{
              position: 'fixed',
              left: calculateDocPosition(DRAWER_WIDTH).left,
              width: calculateDocPosition(DRAWER_WIDTH).width,
              maxHeight: calculateDocPosition(DRAWER_WIDTH).height,
              top: '10%',
              overflow: 'scroll',
              textAlign: 'center',
            }}
          >
            {fileMimeType == 'application/pdf' && (
              <PDFViewer
                fileUrl={fileUrl}
                pageWidth={calculateDocPosition(DRAWER_WIDTH).width}
              />
            )}
            {fileMimeType != 'application/pdf' && <img src={fileUrl} />}
            {/* <Document file='https://voltappteststorage.blob.core.windows.net/test-container/gescan-invoice-1.png?sp=r&st=2023-09-14T00:56:45Z&se=2023-09-14T08:56:45Z&spr=https&sv=2022-11-02&sr=b&sig=eIz7X1B6asHsiSA2MzwfQL4hMTJVq63Y5EReIhd6jic%3D'> */}
          </div>
        )}
      </DrawerForm>
    </MaterialBulkCreateOverlayStyled>
  )
}

MaterialBulkCreateOverlay.displayName = 'MaterialBulkCreateOverlay'
