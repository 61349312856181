import { Typography, Flex } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { MaterialNameDescriptionStyled } from './styles'
import { VoltProIcon } from '../VoltProIcon'

const { Paragraph } = Typography

export interface MaterialNameDescriptionProps {
  name: string
  description?: string | null
  productLinkId?: string | null
  copyName?: boolean
  boldName?: boolean
  globalCatalog?: boolean
}
const NameContent = React.memo(
  ({
    name,
    boldName,
    copyName,
    globalCatalog,
  }: Pick<
    MaterialNameDescriptionProps,
    'name' | 'boldName' | 'copyName' | 'globalCatalog'
  >) => (
    <Flex gap='small' align='center'>
      {globalCatalog && (
        <span style={{ opacity: '0.35' }}>
          <VoltProIcon tooltipTitle='VoltPro global price catalog' />
        </span>
      )}
      <Paragraph
        strong={boldName}
        copyable={!!name && copyName}
        style={{ marginBottom: 0 }}
        ellipsis
        data-testid='material-name'
      >
        {name || '-'}
      </Paragraph>
    </Flex>
  )
)
NameContent.displayName = 'NameContent'

const DescriptionContent = React.memo(
  ({ description }: Pick<MaterialNameDescriptionProps, 'description'>) =>
    description ? (
      <Paragraph
        type='secondary'
        style={{ marginBottom: 0 }}
        ellipsis
        data-testid='material-trade-name'
      >
        {description}
      </Paragraph>
    ) : null
)
DescriptionContent.displayName = 'DescriptionContent'

const NameDescription = React.memo(
  ({
    name,
    description,
    boldName,
    copyName,
    globalCatalog,
  }: Omit<MaterialNameDescriptionProps, 'productLinkId'>) => (
    <Typography>
      <Flex vertical gap={4}>
        <NameContent
          name={name}
          boldName={boldName}
          copyName={copyName}
          globalCatalog={globalCatalog}
        />
        <DescriptionContent description={description} />
      </Flex>
    </Typography>
  )
)

NameDescription.displayName = 'NameDescription'

export const MaterialNameDescription = React.memo(
  ({
    name,
    description,
    productLinkId,
    copyName = true,
    boldName = true,
    globalCatalog,
  }: MaterialNameDescriptionProps) => (
    <MaterialNameDescriptionStyled>
      {productLinkId ? (
        <a
          href={`/materials/${productLinkId}`}
          target='_blank'
          rel='noreferrer'
          style={{ color: theme.colors.primary }}
        >
          <NameDescription
            name={name}
            description={description}
            boldName={boldName}
            copyName={copyName}
            globalCatalog={globalCatalog}
          />
        </a>
      ) : (
        <NameDescription
          name={name}
          description={description}
          boldName={boldName}
          copyName={copyName}
          globalCatalog={globalCatalog}
        />
      )}
    </MaterialNameDescriptionStyled>
  )
)

MaterialNameDescription.displayName = 'MaterialNameDescription'
