// packages/app/src/components/EstimatesForm/EstimatesMaterialsForm/EstimatesMaterialsFormTable/EditingContext.tsx
import React from 'react'

// Define the context type
interface EditingContextType {
  newRowKey: string | null
  setNewRowKey: (key: string | null) => void
}

// Create the context with default values
export const EditingContext = React.createContext<EditingContextType>({
  newRowKey: null,
  setNewRowKey: () => {},
})

// Custom hook for consuming the context
export const useEditing = () => React.useContext(EditingContext)
