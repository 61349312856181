import {
  DeleteOutlined,
  PlusOutlined,
  FontColorsOutlined,
  MoreOutlined,
  ArrowUpOutlined,
  MenuOutlined,
} from '@ant-design/icons'
import { Row, Col, Button, Dropdown, Space } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { ListButtonBarStyled } from './styles'
export interface ListButtonBarProps {
  showClearAll?: boolean
  onAddClick?: () => void
  onAddAssemblyClick?: () => void
  onClearAllClick?: () => void
  dropdownButtonItems?: any[]
  fullWidth?: boolean
}

export const ListButtonBar = ({
  showClearAll = false,
  onAddClick = () => {},
  onAddAssemblyClick = () => {},
  onClearAllClick = () => {},
  dropdownButtonItems,
  fullWidth,
  ...props
}: ListButtonBarProps) => {
  return (
    <ListButtonBarStyled>
      <Space.Compact size={fullWidth ? 'large' : 'middle'} block={fullWidth}>
        {!dropdownButtonItems && <Button onClick={onAddClick}>Add item</Button>}

        {dropdownButtonItems && (
          <Space>
            <Button onClick={onAddClick} data-testid='list-add-item-button'>
              Add item
            </Button>
            <Button
              onClick={onAddAssemblyClick}
              data-testid='list-add-assembly-button'
            >
              Add assembly
            </Button>
            <Dropdown menu={{ items: dropdownButtonItems }}>
              <Button data-testid='list-add-item-more-button'>More</Button>
            </Dropdown>
          </Space>
        )}
        {showClearAll && (
          <Button
            onClick={onClearAllClick}
            block
            danger
            data-testid='list-clear-all-items-button'
          >
            Clear All
          </Button>
        )}
      </Space.Compact>
    </ListButtonBarStyled>
  )
}

ListButtonBar.displayName = 'ListButtonBar'
