import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const MaterialSelectOptionRenderStyled = styled.div`
  width: 100%;

  .ant-typography {
    max-width: 100%;
  }
  .ant-row {
    width: 100%;
    overflow: hidden;
  }
`
