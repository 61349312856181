import {
  CloseOutlined,
  CloseSquareOutlined,
  ToolOutlined,
  FormOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  EditOutlined,
  DollarOutlined,
} from '@ant-design/icons'
import { PlusSquare, Layers } from 'react-feather'
import {
  Button,
  Table,
  Typography,
  Modal,
  Tooltip,
  List,
  Space,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  Flex,
} from 'antd'
import * as React from 'react'

import { dollarInputFormatter, dollarInputParser } from '../../../../utils'
import { useGetProductsQuery } from '../../../../graphql/_generated-hooks'

import { theme } from '../../../../styles/themes/default'
import { MaterialDataType } from './MaterialData.types'
import { QUANTITY_PRECISION } from '../../../../config/default'

import {
  formatMoney,
  calculateLineItemTotal,
  calculateLineItemProfit,
} from '../../../../utils'

import { ServiceItemBadge } from '../../../ServiceItemBadge'
import { MaterialSelectItemRender } from '../../../MaterialSelectItemRender'
import { MaterialNameDescription } from '../../../MaterialNameDescription'
import { ListButtonBar } from '../../../ListButtonBar'
import { WastageIcon } from '../../../WastageIcon'
import { FixedPriceProfitCostIcon } from '../../../FixedPriceProfitCostIcon'
import { SimpleSearchSelect } from '../../../SimpleSearchSelect'
import { MaterialsListItemMobile } from '../../../MaterialsList/MaterialsListItemMobile'
import { MaterialsListItemSectionMobile } from '../../../MaterialsList/MaterialsListItemSectionMobile'
import { EstimatesMaterialsFormListStyled } from './styles'

type EditableTableProps = Parameters<typeof Table>[0]
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>

export interface EstimatesMaterialsFormListProps {
  initialData?: MaterialDataType[] | void
  appliedAssembly?: MaterialDataType[] | void
  disableMarkup?: boolean
  inlineCreatedMaterials?: {
    [key: string]: { id: string; prices: { id: string; unit_amount: number }[] }
  }
  onMaterialsChange?: (newData?: any[], row?: MaterialDataType) => void
  onCreateNewMaterialClick?: (record: MaterialDataType) => void
  onAddAssemblyClick?: () => void
  onInputError?: (error: any, record: MaterialDataType) => void
}

const getMaterialSelectItemRenderComponent = (item: any) => {
  return (
    <MaterialSelectItemRender
      title={item.name}
      subtitle={item.description}
      globalCatalog={item.is_global}
    />
  )
}
const getLastItemMarkup = (items: any) => {
  if (!items || !items.length) return 0

  return items[items.length - 1]?.markup || 0
}

export const EstimatesMaterialsFormList = ({
  initialData = [],
  appliedAssembly = [],
  disableMarkup,
  inlineCreatedMaterials,
  onMaterialsChange = () => {},
  onCreateNewMaterialClick = () => {},
  onAddAssemblyClick = () => {},
  onInputError = () => {},
}: EstimatesMaterialsFormListProps) => {
  const [form] = Form.useForm()

  const [editingIndex, setEditingIndex] = React.useState(-1)
  const [isCreating, setIsCreating] = React.useState<boolean>(false)
  const [lineTotal, setLineTotal] = React.useState<any>(0)
  const [fixedPriceSelected, setFixedPriceSelected] = React.useState<any>({})
  const [initialProductList, setInitialProductList] = React.useState<any>([])
  const [dataSource, setDataSource] =
    React.useState<MaterialDataType[]>(initialData)

  React.useEffect(() => {
    setDataSource(initialData)
  }, [initialData]) // Dependency on initialData

  React.useEffect(() => {
    if (appliedAssembly && appliedAssembly.length) {
      setDataSource([...dataSource, ...appliedAssembly])
      onMaterialsChange([...dataSource, ...appliedAssembly])
    }
  }, [appliedAssembly]) // Dependency on appliedAssembly

  React.useEffect(() => {
    if (inlineCreatedMaterials && Object.keys(inlineCreatedMaterials).length) {
      const dataSourceCopy = [...dataSource]

      for (let key in inlineCreatedMaterials) {
        const foundIndex = dataSourceCopy.findIndex(
          (material) => material.key == key
        )
        const inlineCreatedMaterial = inlineCreatedMaterials[key]
        const inlineCreatedLatestPrice = inlineCreatedMaterial.prices?.[0]

        if (foundIndex > -1) {
          dataSourceCopy[foundIndex] = {
            ...dataSourceCopy[foundIndex],
            name: {
              label: dataSourceCopy[foundIndex]?.name?.label || '',
              value: inlineCreatedMaterial.id,
            },
            productId: inlineCreatedMaterial.id, // TODO bug if no priceId, backend doesn't save product properly
            priceId: inlineCreatedLatestPrice?.id,
            ...(inlineCreatedLatestPrice?.unit_amount && {
              price: inlineCreatedLatestPrice?.unit_amount,
            }),
            custom: false,
            recentlyCreated: true,
          }
        }
      }

      setDataSource(dataSourceCopy)
      onMaterialsChange(dataSourceCopy) // Trigger save to parent form
    }
  }, [inlineCreatedMaterials])

  const {
    data: { products = { data: [] } } = { products: { data: [] } },
    refetch: refetchProducts,
  } = useGetProductsQuery({
    variables: { input: { limit: 100, includeGlobal: true } },
    onCompleted: (data) => {
      setInitialProductList(data.products?.data)
    },
  })

  const handleAdd = (type: string = 'good', custom?: boolean) => {
    const newData: MaterialDataType = {
      key: Math.random().toString(36),
      quantity: 1,
      markup: getLastItemMarkup(dataSource),
      price: 0,
      total: 0,
      type,
      custom,
    }
    const newDataSource = [...dataSource, newData]
    setDataSource(newDataSource)
    onMaterialsChange(newDataSource)

    setEditingIndex(newDataSource.length - 1)
    setIsCreating(true)
    form.resetFields()
  }

  const handleRemove = (key: number) => {
    const newData = dataSource.filter((item: any) => item.key !== key)
    setDataSource(newData)
    onMaterialsChange(newData, {
      key,
      quantity: 0,
      price: 0,
      markup: 0,
      total: 0,
    })

    setEditingIndex(-1)
    form.resetFields()
  }
  const handleCancel = (index: number, key: number) => {
    const { name, quantity, price, markup } = form.getFieldsValue()

    if (
      index === dataSource.length - 1 &&
      isCreating &&
      !form.isFieldsTouched()
    ) {
      handleRemove(key)
    }
    setEditingIndex(-1)
  }

  const handleSave = React.useCallback(
    (row: MaterialDataType) => {
      const newData = [...dataSource]
      const index = newData.findIndex((item) => row.key === item.key)

      const item = newData[index]
      newData.splice(index, 1, {
        ...item,
        ...row,
      })

      onMaterialsChange(newData, row)
      setDataSource(newData)
    },
    [dataSource, onMaterialsChange]
  )

  const handleFetchProducts = async (value: string): Promise<any> => {
    return refetchProducts({
      input: { search: value, limit: 100, includeGlobal: true },
    }).then(({ data }: any) => {
      return data?.products?.data?.map((item: any) => ({
        value: item.id,
        data: item,
        label: getMaterialSelectItemRenderComponent(item),
      }))
    })
  }
  const handleFormatOptionsList = React.useCallback(
    (data?: any) => {
      return initialProductList.map((item: any) => ({
        value: item.id,
        data: item,
        label: getMaterialSelectItemRenderComponent(item),
      }))
    },
    [initialProductList] // Only update if the initialProductList changes
  )
  const handleRemoveAll = () => {
    setDataSource([])
    onMaterialsChange([])
  }

  const handleReset = () => {
    Modal.confirm({
      title: `Remove All?`,
      content: 'Are you sure you want to remove all materials?',
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return handleRemoveAll()
      },
    })
  }

  const getInfoColumnElement = (record: MaterialDataType) => {
    const {
      key,
      type,
      custom,
      recentlyCreated,
      name,
      wastage_amount,
      wastage_percentage,
      total_coverage_rate,
      fixed_price,
      price,
      quantity,
      markup,
    } = record

    // Allow quick create button
    if (wastage_amount && wastage_amount > 0) {
      return (
        <WastageIcon
          percent={wastage_percentage}
          amount={wastage_amount}
          coverageCapacity={total_coverage_rate}
        />
      )
    } else if (fixed_price && !disableMarkup) {
      return (
        <FixedPriceProfitCostIcon
          cost={calculateLineItemTotal(price, quantity)}
          profit={calculateLineItemProfit(price, quantity, markup, fixed_price)}
        />
      )
    }
  }

  const save = async (
    form: any,
    record?: any,
    customRecordUpdates: any = {}
  ) => {
    try {
      const values = await form.validateFields()

      // Prevents the case where a markup is set, then
      // a fixed price is selected, but the markup is still applied
      // from the previous item.
      if (!values.markup) {
        record.markup = 0
      }
      if (!values.type) {
        delete values.type
      }

      setEditingIndex(-1)

      handleSave({ ...record, ...values, ...customRecordUpdates })

      form.resetFields()
    } catch (errInfo) {
      // Pass error to parent to block submit functionality
      onInputError(errInfo, record)
    }
  }
  const handleUpdateLineTotal = () => {
    const { price, quantity, markup, fixed_price } = form.getFieldsValue()

    if (fixed_price) {
      return setLineTotal(
        calculateLineItemTotal(price, quantity, 0, fixed_price)
      )
    }

    setLineTotal(calculateLineItemTotal(price, quantity, markup, fixed_price))
  }
  const handleOnSelectMateral = (materialData: any = {}, key: string) => {
    const {
      name,
      description,
      prices = [],
      fixed_price,
      type = 'good',
    } = materialData

    if (fixed_price) {
      setFixedPriceSelected({
        ...fixedPriceSelected,
        [key]: fixed_price,
      })
      form.setFieldValue('markup', null)
    } else {
      setFixedPriceSelected({
        ...fixedPriceSelected,
        [key]: null,
      })
    }

    form.setFieldValue('_name', name)
    form.setFieldValue('description', description)
    form.setFieldValue('type', type)
    form.setFieldValue('fixed_price', fixed_price)
    form.setFieldValue('price', prices?.[0]?.unit_amount || 0)
    if (prices && prices?.[0]?.id) {
      form.setFieldValue('priceId', prices?.[0]?.id)
    }
    handleUpdateLineTotal()
  }
  const handleEditItem = (record: any, index: number) => {
    form.setFieldsValue(record)
    setEditingIndex(index)
    setIsCreating(false)

    const { price, quantity, markup, fixed_price } = record
    setLineTotal(calculateLineItemTotal(price, quantity, markup, fixed_price))
  }

  const dropdownButtonItems = [
    {
      key: '1',
      icon: <PlusSquare size={14} />,
      label: 'Add Custom Material',
      onClick: () => {
        handleAdd('good', true)
      },
      'data-testid': 'list-add-custom-material-button',
    },
    {
      key: '2',
      icon: <ToolOutlined size={20} />,
      label: 'Add Custom Labor',
      onClick: () => {
        handleAdd('service', true)
      },
      'data-testid': 'list-add-labor-button',
    },
    {
      key: '3',
      icon: <FormOutlined size={20} />,
      label: 'Add Section',
      onClick: () => {
        handleAdd('section')
      },
      'data-testid': 'list-add-section-button',
    },
  ]

  const renderSectionItem = (item: any, index: number) => {
    return (
      <MaterialsListItemSectionMobile
        title={item.name?.label}
        actions={[
          <a
            type='text'
            key={'edit-material-list-item'}
            onClick={() => handleEditItem(item, index)}
          >
            <EditOutlined />
          </a>,
        ]}
      />
    )
  }
  const renderListItem = (item: any, index: number) => {
    const {
      type,
      custom,
      recentlyCreated,
      _name,
      name,
      description,
      wastage_amount,
      wastage_percentage,
      total_coverage_rate,
      fixed_price,
      price,
      quantity,
      markup,
    } = item || {}
    const markupAmount = disableMarkup ? 0 : markup

    return (
      <MaterialsListItemMobile
        title={
          <div className='item-name'>
            {type === 'service' ? (
              <div className='item-header' style={{ marginBottom: '10px' }}>
                <span style={{ marginRight: '2px' }}>{name?.label}</span>
                <ServiceItemBadge />
              </div>
            ) : (
              <MaterialNameDescription
                copyName={false}
                boldName={false}
                name={_name || name?.label}
                description={description || ''}
                globalCatalog={item.priceId === '_global'}
              />
            )}
          </div>
        }
        total={
          <Space direction='horizontal'>
            {getInfoColumnElement(item)}
            {formatMoney(
              calculateLineItemTotal(price, quantity, markupAmount, fixed_price)
            )}
          </Space>
        }
        metaInfo={[
          {
            label: 'Quantity',
            value: quantity || 1,
          },
          {
            label: 'Price',
            value: formatMoney(fixed_price || price),
          },
          {
            label: 'Markup',
            value: !fixed_price && !disableMarkup ? `${markup}%` : '-',
          },
        ]}
        actions={[
          <Button
            size='small'
            key={'edit-material-list-item'}
            icon={<EditOutlined />}
            onClick={() => handleEditItem(item, index)}
          />,
        ]}
      />
    )
  }
  const renderItemInput = (record: any) => {
    if (record.type === 'section') {
      return (
        <Form.Item
          style={{ margin: 0, width: '100%' }}
          name={['name', 'label']}
          label='Section name'
          rules={[
            {
              required: true,
              message: 'Item required',
            },
          ]}
        >
          <Input placeholder={'Section Name'} />
        </Form.Item>
      )
    } else if (record.custom) {
      return (
        <Form.Item
          style={{ margin: 0, width: '100%' }}
          name={['name', 'label']}
          label='Item'
          rules={[
            {
              required: true,
              message: 'Item required',
            },
          ]}
        >
          <Input
            placeholder={record.type === 'service' ? 'Labor' : 'Material'}
            suffix={record.type === 'service' && <ServiceItemBadge />}
          />
        </Form.Item>
      )
    } else {
      return (
        <Form.Item
          style={{ margin: 0, width: '100%' }}
          name={'name'}
          label='Item'
          rules={[
            {
              required: true,
              message: 'Item required',
            },
          ]}
        >
          <SimpleSearchSelect
            labelInValue={true}
            onSelect={(value: any, option: any) => {
              handleOnSelectMateral(option.data, record.key)
            }}
            placeholder='Search by item name'
            fetchOptions={handleFetchProducts}
            optionList={handleFormatOptionsList(products)}
            suffixIcon={record.type === 'service' ? <ServiceItemBadge /> : ''}
            style={{ margin: 0, width: '100%' }}
          />
        </Form.Item>
      )
    }
  }
  const renderListItemForm = (item: any, index: number) => {
    return (
      <List.Item>
        <Form
          form={form}
          component={false}
          name='estiamtes_materials_list_form'
        >
          <Form.Item hidden name={'_name'}>
            <InputNumber data-testid='estimate-item-price-name-hidden-input' />
          </Form.Item>
          <Form.Item hidden name={'description'}>
            <InputNumber data-testid='estimate-item-price-description-hidden-input' />
          </Form.Item>
          <Form.Item hidden name={'priceId'}>
            <InputNumber data-testid='estimate-item-price-id-hidden-input' />
          </Form.Item>
          <Form.Item hidden name={'fixed_price'}>
            <InputNumber data-testid='estimate-item-fixed-rate-hidden-input' />
          </Form.Item>
          <Form.Item hidden name={'type'}>
            <Input data-testid='estimate-item-type-hidden-input' />
          </Form.Item>
          <Space
            direction='vertical'
            style={{ width: '100%', maxWidth: '100%' }}
          >
            <Row gutter={[12, 16]} style={{ marginBottom: '12px' }}>
              <Col span={24}>{renderItemInput(item)}</Col>
            </Row>
            {item.type !== 'section' && (
              <Row gutter={[12, 16]} style={{ marginBottom: '24px' }}>
                <Col span={24}>
                  <Form.Item
                    style={{ margin: 0, width: '100%' }}
                    name={'quantity'}
                    label='Quantity'
                    rules={[
                      {
                        required: true,
                        message: 'Missing quantity',
                      },
                    ]}
                    initialValue={1}
                  >
                    <InputNumber
                      precision={QUANTITY_PRECISION}
                      placeholder='Quantity'
                      onChange={handleUpdateLineTotal}
                      style={{ margin: 0, width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {fixedPriceSelected[item.key] && (
                    <Flex
                      align='flex-end'
                      justify='flex-end'
                      style={{ height: '100%' }}
                    >
                      <Typography.Text>
                        {formatMoney(fixedPriceSelected[item.key])}
                      </Typography.Text>
                    </Flex>
                  )}
                  <Form.Item
                    style={{ margin: 0, width: '100%' }}
                    name={'price'}
                    label='Price'
                    rules={[
                      {
                        required: true,
                        message: 'Missing price',
                      },
                    ]}
                    hidden={fixedPriceSelected[item.key]}
                  >
                    {/* <Input defaultValue={0} /> */}
                    <InputNumber
                      placeholder='Price'
                      addonBefore='$'
                      decimalSeparator='.'
                      formatter={dollarInputFormatter}
                      parser={dollarInputParser}
                      onChange={handleUpdateLineTotal}
                      style={{ margin: 0, width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {!fixedPriceSelected[item.key] && (
                    <Form.Item
                      style={{ margin: 0, width: '100%' }}
                      name={'markup'}
                      label='Markup'
                      rules={[
                        {
                          required: false,
                          message: 'Missing markup',
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        placeholder='Markup'
                        formatter={(value) =>
                          `${value}%`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        onChange={handleUpdateLineTotal}
                        style={{ margin: 0, width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col span={24}>
                  <Flex
                    justify='flex-end'
                    align='flex-end'
                    style={{
                      height: '100%',
                      paddingBottom: '12px',
                      borderBottom: '1px solid #eee',
                    }}
                  >
                    <Typography.Text strong style={{ fontSize: '16px' }}>
                      {formatMoney(lineTotal)}
                    </Typography.Text>
                  </Flex>
                </Col>
              </Row>
            )}
            <Flex justify='space-between' align='center'>
              <Button onClick={() => handleRemove(item.key)} danger>
                Remove
              </Button>
              <Space>
                <Button onClick={() => handleCancel(index, item.key)}>
                  Cancel
                </Button>
                <Button type='primary' onClick={() => save(form, item)}>
                  Save
                </Button>
              </Space>
            </Flex>
          </Space>
        </Form>
      </List.Item>
    )
  }

  return (
    <EstimatesMaterialsFormListStyled>
      <List
        itemLayout='vertical'
        dataSource={dataSource}
        renderItem={(item: any, index: number) => {
          if (editingIndex === index) {
            return renderListItemForm(item, index)
          } else {
            switch (item.type) {
              case 'section':
                return renderSectionItem(item, index)
              default:
                return renderListItem(item, index)
            }
          }
        }}
      />

      <ListButtonBar
        onAddClick={handleAdd}
        onAddAssemblyClick={onAddAssemblyClick}
        dropdownButtonItems={dropdownButtonItems}
        fullWidth
      />
    </EstimatesMaterialsFormListStyled>
  )
}

EstimatesMaterialsFormList.displayName = 'EstimatesMaterialsFormList'
