import { Tag, Button } from 'antd'
import { ClearOutlined } from '@ant-design/icons'
import * as React from 'react'

import { CategoryTableFilterStyled } from './styles'

import { useGetCategoriesQuery } from '../../graphql/_generated-hooks'
import { SimpleSearchSelect } from '../SimpleSearchSelect'

export interface CategoryTableFilterProps {
  initialValue?: any
  width?: number
  onClear?: () => void
  onChange?: (value: string, option: any) => void
}

export const CategoryTableFilter = ({
  onClear,
  onChange = (value: string, option: any) => {},
  initialValue,
  width = 200,
  ...props
}: CategoryTableFilterProps) => {
  const {
    data: { categories = { data: [] } } = { categories: { data: [] } },
    refetch: refetchCategories,
  } = useGetCategoriesQuery({
    variables: { input: { limit: 100 } },
  })

  const handleFetchProducts = async (value: string): Promise<any> => {
    return refetchCategories({ input: { search: value, limit: 100 } }).then(
      ({ data }: any) => {
        return data?.categories?.data?.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))
      }
    )
  }

  return (
    <CategoryTableFilterStyled {...props}>
      <div>
        <SimpleSearchSelect
          placeholder='Select a category'
          fetchOptions={handleFetchProducts}
          optionList={categories?.data?.map((item: any) => ({
            value: item.id,
            label: item.name,
          }))}
          onChange={(value: any, option: any) => {
            onChange(value, option)
          }}
          style={{ width: '100%' }}
          labelInValue={true}
          defaultValue={initialValue}
          allowClear
        />
      </div>
    </CategoryTableFilterStyled>
  )
}

CategoryTableFilter.displayName = 'CategoryTableFilter'
