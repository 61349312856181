import { CloseOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Form,
  FormProps,
  Input,
  InputProps,
  InputNumber,
  Checkbox,
  List,
  message,
  Row,
  Switch,
  Spin,
  Typography,
} from 'antd'
import FormItemLabel from 'antd/lib/form/FormItemLabel'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  formatMoney,
  buildRedirectString,
  dollarInputFormatter,
  dollarInputParser,
} from '../../utils'
import {
  useGetTaxesQuery,
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
  useUpdateOrganizationSettingsMutation,
} from '../../graphql/_generated-hooks'
import { AnimatedComponent } from '../../components/AnimatedComponent'
import { PageTitle } from '../../components/PageTitle'
import { RichTextEditor } from '../../components/RichTextEditor'
import { TaxInputManager } from '../../components/TaxInputManager'
import { PreferenceEstimatesViewStyled } from './styles'

export interface PreferenceEstimatesViewProps {
  me?: any
  redirectObj?: any
}

export const PreferenceEstimatesView = ({
  me,
  redirectObj,
  ...props
}: PreferenceEstimatesViewProps) => {
  const [taxOptions, setTaxOptions] = React.useState<any[]>([])
  const [appliedTaxes, setAppliedTaxes] = React.useState<any[]>([])
  const [redirectUrl, setRedirectUrl] = React.useState('')
  // const { data: { organization } = {}, loading } = useGetOrganizationQuery()
  const navigate = useNavigate()
  const [updateOrganization] = useUpdateOrganizationMutation()
  const [updateOrganizationSettings, { loading: savingOrganiztionSettings }] =
    useUpdateOrganizationSettingsMutation()

  const {
    data: { taxes = [] } = { taxes: [] },
    loading: isLoadingTax,
    refetch: refetchTax,
    updateQuery: updateTax,
  } = useGetTaxesQuery({
    variables: { input: {} },
    onCompleted: (data) => {
      const { taxes } = data
      if (taxes?.length) {
        setTaxOptions(taxes)
      }
    },
  })

  React.useEffect(() => {
    if (me) {
      const { organization } = me
      if (organization && organization.settings) {
        form.setFieldsValue({
          ...organization.settings,
        })

        if (!organization.settings.default_contact_name) {
          form.setFieldValue(
            'default_contact_name',
            `${me?.given_name} ${me?.family_name}`
          )
        }
        if (!organization.settings.default_contact_email) {
          form.setFieldValue('default_contact_email', me?.email)
        }

        if (organization.settings.default_taxes?.length) {
          setAppliedTaxes([...organization.settings.default_taxes])
        }
      }
    }

    if (redirectObj) {
      setRedirectUrl(buildRedirectString(redirectObj))
    }
  }, [me, redirectObj])

  const [form] = Form.useForm()

  const initialValues = {
    logo: null,
    color_brand: '',
    color_accent: '',
  }

  const onSubmit: FormProps['onFinish'] = (values) => {
    const { selected_tax_id, ...rest } = values
    updateOrganizationSettings({
      variables: {
        input: {
          ...rest,
          default_taxes: appliedTaxes.map((tax) => {
            return {
              id: tax.id,
              display_name: tax.display_name,
              percentage: tax.percentage,
            }
          }),
        },
      },
      onCompleted: () => {
        message.success('Updated successfully')
        if (redirectUrl) {
          navigate(redirectUrl)
        }
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  }
  const handleApplyTax = (tax: any) => {
    setAppliedTaxes([...appliedTaxes, tax])

    // Reset input
    form.setFieldValue('selected_tax_id', null)
  }
  const handleRemoveTax = (tax: any) => {
    const remainingTaxes = appliedTaxes.filter(
      (taxObj) => taxObj?.id !== tax?.id
    )
    setAppliedTaxes([...remainingTaxes])
  }

  return (
    <PreferenceEstimatesViewStyled>
      <AnimatedComponent>
        <Content style={{ padding: '2em' }}>
          <PageTitle
            title='Estimate Settings'
            subtitle='Set default values and other estimate settings.'
          />

          <Spin spinning={!me}>
            <Card style={{ marginTop: '2em', maxWidth: '800px' }}>
              <Form
                form={form}
                initialValues={initialValues}
                layout='vertical'
                onFinish={onSubmit}
              >
                <Row gutter={[12, 24]}>
                  <Col span={24}>
                    <Typography.Title level={4}>Labor</Typography.Title>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='default_labor_label'
                      label='Default Label'
                      rules={[
                        {
                          required: false,
                          message: 'Label is required.',
                        },
                      ]}
                    >
                      <Input placeholder={'Labor'} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='default_labor_rate'
                      label='Default Rate'
                      rules={[
                        {
                          required: false,
                          message: 'Rate is required.',
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder={'1'}
                        decimalSeparator='.'
                        style={{ width: '100%' }}
                        formatter={dollarInputFormatter}
                        parser={dollarInputParser}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[12, 24]}>
                  <Col span={24}>
                    <Typography.Title level={4}>Tax</Typography.Title>
                    <Typography.Text
                      style={{ marginTop: '0.5em' }}
                      type='secondary'
                    >
                      {`Apply taxes that will automatically be applied to estimates.`}
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Form.Item name='selected_tax_id'>
                      <TaxInputManager
                        taxOptions={taxOptions}
                        appliedTaxes={appliedTaxes}
                        onApplyTax={handleApplyTax}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <List>
                      {appliedTaxes.map((tax) => (
                        <List.Item
                          key={tax?.id}
                          actions={[
                            <Button
                              key='list-remove-item'
                              onClick={() => handleRemoveTax(tax)}
                              type='text'
                              size='small'
                              icon={<CloseOutlined />}
                              danger
                            />,
                          ]}
                        >
                          <Typography.Text>
                            {tax?.display_name} ({tax?.percentage}%)
                          </Typography.Text>
                        </List.Item>
                      ))}
                    </List>
                  </Col>
                </Row>
                <Row gutter={[12, 24]}>
                  <Col span={24}>
                    <Typography.Title level={4}>
                      Global Product Catalog
                    </Typography.Title>
                    <Typography.Text
                      style={{ marginTop: '0.5em' }}
                      type='secondary'
                    >
                      {`Show or hide global products in the estimate item search and material catalog search.`}
                    </Typography.Text>
                  </Col>
                  <Col span={12}>
                    <Form.Item name='show_global_products'>
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[12, 24]}>
                  <Col span={16}>
                    <Typography.Title level={4}>Content</Typography.Title>
                    <Typography.Text
                      style={{ marginTop: '0.5em' }}
                      type='secondary'
                    >
                      {`This content will prefill the 'Additional Content' section
                    in the estimate form. Use this to add any standard
                    exceptions or payment terms.`}
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Form.Item name='default_additional_content'>
                      <RichTextEditor
                        initialContent={
                          me?.organization?.settings?.default_additional_content
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[12, 24]}>
                  <Col span={16}>
                    <Typography.Title level={4}>
                      Contact Information
                    </Typography.Title>
                    <Typography.Text
                      style={{ marginTop: '0.5em' }}
                      type='secondary'
                    >
                      This content will be displayed as your contact information
                      in estimate PDFs.
                    </Typography.Text>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='default_contact_name'
                      label='Default Contact Name'
                      rules={[
                        {
                          required: false,
                          message: 'Default contact name is required.',
                        },
                      ]}
                    >
                      <Input placeholder={'John Doe'} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='default_contact_email'
                      label='Default Contact Email'
                      rules={[
                        {
                          required: false,
                          message: 'Default contact email is required.',
                        },
                      ]}
                    >
                      <Input placeholder={'payments@yourcompany.com'} />
                    </Form.Item>
                  </Col>
                </Row>

                <Button
                  htmlType='submit'
                  type='primary'
                  loading={savingOrganiztionSettings}
                  style={{ marginTop: '2em', float: 'right' }}
                >
                  Save changes
                </Button>
              </Form>
            </Card>
          </Spin>
        </Content>
      </AnimatedComponent>
    </PreferenceEstimatesViewStyled>
  )
}

PreferenceEstimatesView.displayName = 'PreferenceEstimatesView'
