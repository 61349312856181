import { Award } from 'react-feather'
import { UserOutlined } from '@ant-design/icons'
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  message,
  Space,
  Spin,
  Flex,
  Typography,
  Upload,
} from 'antd'
import debounce from 'lodash/debounce'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

import { buildRedirectString } from '../../utils'
import { useAnalytics } from '../../analytics/AnalyticsProvider'
import { AnimatedComponent } from '../../components/AnimatedComponent'
import { PageTitle } from '../../components/PageTitle'
import { StripePricingTable } from '../../components/StripePricingTable'
import { useAppStore } from '../../stores/appStore'
import { theme } from '../../styles/themes/default'
import {
  useGetMeQuery,
  useGetUserPortalSessionQuery,
  useGetUserPortalSessionLazyQuery,
  useGetUserCustomerSessionQuery,
  useGetUserCustomerSessionLazyQuery,
  useUpdateMeMutation,
} from '../../graphql/_generated-hooks'
import { PreferenceBillingViewStyled } from './styles'

export interface PreferenceBillingViewProps {
  me?: any
  redirectObj?: any
  mobile?: boolean
}

export const PreferenceBillingView = ({
  me,
  redirectObj,
  mobile,
}: PreferenceBillingViewProps) => {
  const { trackEvent, debouncedTrackEvent } = useAnalytics()
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()
  const [redirectUrl, setRedirectUrl] = React.useState('')
  const [showSuccessModal, setShowSuccessModal] = React.useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  // const [manageBillingUrl, setManageBillingUrl] = React.useState<any>('')
  // const [userCustomerSession, setUserCustomerSession] = React.useState<any>('')
  const activePlan = useAppStore((state: any) => state.plan)
  const setPlan = useAppStore((state: any) => state.setPlan)

  const [
    getUserPortalSession,
    { data: userPortalSession, loading: loadingPortalSession },
  ] = useGetUserPortalSessionLazyQuery()

  const [
    getUserCustomerSession,
    { data: userCustomerSession, loading: loadingSession },
  ] = useGetUserCustomerSessionLazyQuery()

  const handleGetSession = React.useCallback(
    debounce(async (activePlan) => {
      if (activePlan) {
        getUserPortalSession()
      } else {
        getUserCustomerSession()
      }
    }, 100),
    []
  )

  React.useEffect(() => {
    if (redirectObj) {
      setRedirectUrl(buildRedirectString(redirectObj))
    }

    handleGetSession(activePlan)
  }, [me, redirectObj, activePlan])

  React.useEffect(() => {
    if (
      searchParams &&
      searchParams.get('checkout_session') &&
      searchParams.get('checkout_session')?.startsWith('cs_')
    ) {
      setPlan('basic_plan')
    }
    if (searchParams && searchParams.get('success')) {
      setShowSuccessModal(true)

      // TODO move this to the backend and track the event there
      // Especially when there are more plans
      debouncedTrackEvent('app__membership_purchased', {
        membership_plan: 'pro',
        membership_amount: 24.99,
        membership_currency: 'USD',
        membership_cycle: 'monthly',
      })
    }
  }, [])

  const handleOnSuccessModalClose = () => {
    setShowSuccessModal(false)
    const params = Object.fromEntries(searchParams.entries())
    delete params['success']
    setSearchParams(params)
  }

  const managePlanButtonStyle = {
    ...(mobile && { width: '100%', marginTop: '1em' }),
  }
  const contentStyle = {
    ...(mobile && { paddingBottom: '0em' }),
  }

  return (
    <PreferenceBillingViewStyled>
      {contextHolder}
      <AnimatedComponent>
        <Content style={{ padding: '2em', ...contentStyle }}>
          <PageTitle
            title='Membership'
            subtitle='Update subscription and other payment settings.'
          />

          <Card style={{ marginTop: '2em', maxWidth: '800px' }}>
            <Row gutter={[12, 0]}>
              <Col span={24}>
                {!activePlan && (
                  <Spin spinning={loadingSession}>
                    <StripePricingTable
                      // email={me?.email}
                      clientSecret={
                        userCustomerSession?.userCustomerSession?.client_secret
                      }
                    />
                  </Spin>
                )}

                {activePlan && (
                  <Spin spinning={loadingPortalSession}>
                    <Card style={{ border: 'none' }}>
                      <Row>
                        <Col xs={24} sm={24} md={12}>
                          <Space direction='vertical'>
                            <Typography.Text
                              style={{ marginTop: '0.5em' }}
                              type='secondary'
                            >
                              You are subscribed to the:
                            </Typography.Text>
                            <Typography.Title
                              level={4}
                              style={{ marginTop: 0 }}
                            >
                              Pro plan
                            </Typography.Title>
                          </Space>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Flex justify='flex-end'>
                            <Button
                              type='primary'
                              href={userPortalSession?.userPortalSession?.url}
                              style={managePlanButtonStyle}
                            >
                              Manage Subscription
                            </Button>
                          </Flex>
                        </Col>
                      </Row>
                    </Card>
                  </Spin>
                )}
              </Col>
            </Row>
          </Card>
        </Content>
      </AnimatedComponent>
      <Modal
        title={'Success'}
        open={showSuccessModal}
        onCancel={handleOnSuccessModalClose}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ type: 'primary' }}
        cancelText={'Get estimating'}
      >
        <img
          width={520}
          style={{
            marginLeft: '-24px',
            marginBottom: '1em',
            marginTop: '0.5em',
          }}
          alt='payment_complete_1.png'
          src='https://voltappstorage01.blob.core.windows.net/public-assets/app/payment_complete_2.png'
        />
        <Typography.Text>
          You&#39;re have successfully subscribed to VoltPro. You have unlocked
          unlimited estimates and material tracking. Visit this page to manage
          your subscription in the future.
        </Typography.Text>
      </Modal>
    </PreferenceBillingViewStyled>
  )
}

PreferenceBillingView.displayName = 'PreferenceBillingView'
