import { rem, lighten, darken } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const EstimatesMaterialsFormTableStyled = styled.div`
  font-size: ${rem(16)};

  .ant-table-cell-with-append {
    padding-right: 0 !important;
    padding-left: 0 !important;

    button {
      margin: 0 !important;
    }
  }

  .editable-cell .editable-cell-value-wrap {
    border-bottom: 1px dashed ${(props) => props.theme.colors.border};
  }

  .section-row {
    background-color: ${(props) => darken(0.0, props.theme.colors.background)};
  }
  .section-row > td {
    &:first-child {
      border-left: 5px solid ${(props) => props.theme.colors.accent};
    }
    .ant-typography {
      font-weight: bold;
    }
    // border-bottom-color: ${(props) => props.theme.colors.accent} !important;
  }

  .item-cell-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .service-badge-container {
    flex-shrink: 0;
    margin-left: 8px;
  }
`
