import { Layout, Grid, Row, Col, Flex, Space } from 'antd'
import * as React from 'react'

import { ListViewLayoutStyled } from './styles'

import { Content } from 'antd/lib/layout/layout'

export interface ListViewLayoutProps {
  header?: React.ReactNode
  search?: React.ReactNode
  pagination?: React.ReactNode
  additionalContent?: React.ReactNode
  children: React.ReactNode
}

const { useBreakpoint } = Grid

export const ListViewLayout = ({
  header,
  search,
  pagination,
  additionalContent,
  children,
  ...props
}: ListViewLayoutProps) => {
  const screens = useBreakpoint()

  const viewPaddingStyles = {
    ...((screens.xs || screens.sm) && { padding: '1em' }),
    ...((screens.md || screens.lg) && { padding: '2em' }), // To support full screen width buttons
  }

  return (
    <ListViewLayoutStyled>
      <Layout style={viewPaddingStyles}>
        {header && <Content>{header}</Content>}
        <Content style={{ marginTop: '2em' }}>
          <Row gutter={[16, 24]}>
            {search && (
              <Col span={24}>
                <Flex justify='flex-end'>{search}</Flex>
              </Col>
            )}

            <Col span={24}>{children}</Col>

            {pagination && <Col span={24}>{pagination}</Col>}
          </Row>

          {additionalContent && additionalContent}
        </Content>
      </Layout>
    </ListViewLayoutStyled>
  )
}

ListViewLayout.displayName = 'ListViewLayout'
