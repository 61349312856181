// src/pages/AuthCallbackView.tsx
import React, { useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser, useClerk } from '@clerk/clerk-react'
import { Spin } from 'antd'
import { H } from 'highlight.run'
import debounce from 'lodash/debounce'

import { useSaveOrganizationUtmDataMutation } from '../../graphql/_generated-hooks'
import { UTMManager } from '../../utils/utmManager'

export const AuthCallbackView: React.FC = () => {
  const navigate = useNavigate()
  const { isSignedIn, user } = useUser()
  const { session } = useClerk()
  const [saveUTMData] = useSaveOrganizationUtmDataMutation()

  const debouncedSaveUTM = useCallback(
    debounce((utmParams) => {
      saveUTMData({ variables: { input: utmParams } })
    }, 500),
    [saveUTMData]
  )

  useEffect(() => {
    // Re-extract UTM parameters from URL after Clerk redirects back
    const utmParams = UTMManager.captureParams()

    // If user is authenticated, save UTM data and navigate to app
    if (isSignedIn && user) {
      // Check if this is a new user (created in last minute)
      const isNewUser =
        user.createdAt &&
        new Date().getTime() - new Date(user.createdAt).getTime() < 60000

      // Track authentication event
      const eventName = isNewUser ? 'app__signup_completed' : 'app__login'
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: eventName,
        userId: user.id,
        sessionId: session?.id,
        authMethod: user.primaryEmailAddress ? 'email' : 'social',
        utm_source: utmParams.utm_source || 'direct',
        utm_medium: utmParams.utm_medium,
        utm_campaign: utmParams.utm_campaign,
      })

      // Track in Highlight for production environments
      if (process.env.NODE_ENV === 'production') {
        H.track(eventName, {
          userId: user.id,
          authMethod: user.primaryEmailAddress ? 'email' : 'social',
          ...utmParams,
        })
      }

      // Save UTM data to user profile
      if (
        utmParams.first_touch_timestamp &&
        Object.keys(utmParams).length > 1
      ) {
        debouncedSaveUTM(utmParams)
      }

      // Navigate to the app
      navigate('/materials')
    }
  }, [isSignedIn, user, navigate, session])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Spin size='large' tip='Completing authentication...' />
    </div>
  )
}

AuthCallbackView.displayName = 'AuthCallbackView'
