import { CopyOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons'
import {
  Button,
  Popconfirm,
  Progress,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import * as React from 'react'

import { ProductListDetailFragment } from '../../graphql/_generated-hooks'
import { MaterialListsTableStyled } from './styles'
import { DemoItemTag } from '../DemoItemTag'

export interface MaterialListsTableProps {
  data: any // TODO: ProductDetailFragment[]
  tableProps: TableProps<ProductListDetailFragment>
  selectedId?: string
  onMaterialListClick?: (template: ProductListDetailFragment) => void
  onMaterialListDelete?: (template: ProductListDetailFragment) => void
  onChange?: (pagination: any, filters: any, sorter: any, extra: any) => void
}

const { Title, Paragraph } = Typography

const tableColumns: ColumnsType<ProductListDetailFragment> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    width: '70%',
    render: (_, { id, name, description }) => (
      <Typography>
        <Paragraph
          // level={5}
          title={name}
          strong={true}
          style={{ marginTop: 0, marginBottom: 0 }}
        >
          {name}
        </Paragraph>
        {description && (
          <Paragraph style={{ marginBottom: 0 }} ellipsis>
            {description}
          </Paragraph>
        )}
      </Typography>
    ),
  },
  {
    title: '',
    dataIndex: 'products',
    key: 'products',
    align: 'center',
    width: '30%',
    render: (_, { id, line_items }) => (
      <Typography>
        <Paragraph style={{ marginTop: 0, marginBottom: 0 }}>
          {line_items?.length || 0} materials
        </Paragraph>
      </Typography>
    ),
  },
  {
    title: '',
    key: 'action',
    fixed: 'right',
    width: 100,
    // eslint-disable-next-line react/display-name
    render: (data, { metadata }) => {
      return metadata?.demo ? (
        // <Badge count={<Info size={16} style={{ color: '#f5222d' }} />} />
        <DemoItemTag />
      ) : (
        <Button
          type='text'
          danger
          icon={<CloseOutlined />}
          onClick={(e) => {
            e.stopPropagation()
            data.onDelete(data)
          }}
        />
      )
    },
  },
]

export const MaterialListsTable = ({
  data = [],
  tableProps: { loading } = {},
  selectedId,
  onMaterialListClick = () => undefined,
  onMaterialListDelete = () => undefined,
  onChange = () => undefined,
}: MaterialListsTableProps) => {
  return (
    <MaterialListsTableStyled>
      <Table<ProductListDetailFragment>
        loading={loading}
        dataSource={data?.map((product: any) => ({
          key: product?.id,
          onDelete: onMaterialListDelete,
          ...product,
        }))}
        columns={tableColumns}
        onRow={(item, index) => ({
          onClick: () => onMaterialListClick(item),
        })}
        onChange={onChange}
        rowClassName={(item) =>
          `selectable ${item.id === selectedId ? 'selected' : ''}`
        }
        pagination={false}
      />
    </MaterialListsTableStyled>
  )
}

MaterialListsTable.displayName = 'MaterialListsTable'
