import {
  CloseOutlined,
  CloseSquareOutlined,
  ToolOutlined,
  FormOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  ArrowDownOutlined,
  DollarOutlined,
} from '@ant-design/icons'
import { PlusSquare, Layers } from 'react-feather'
import {
  Button,
  Table,
  Typography,
  Modal,
  Tooltip,
  Popover,
  Space,
  Flex,
} from 'antd'
import * as React from 'react'
import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { useGetProductsQuery } from '../../../../graphql/_generated-hooks'

import { theme } from '../../../../styles/themes/default'
import { MaterialDataType } from './MaterialData.types'

import {
  formatMoney,
  calculateLineItemTotal,
  calculateLineItemProfit,
} from '../../../../utils'

import { EditingContext } from './EditingContext'
import { ServiceItemBadge } from '../../../ServiceItemBadge'
import { MaterialSelectItemRender } from '../../../MaterialSelectItemRender'
import { ListButtonBar } from '../../../ListButtonBar'
import { WastageIcon } from '../../../WastageIcon'
import { FixedPriceProfitCostIcon } from '../../../FixedPriceProfitCostIcon'
import { FormTableRow, FormTableRowDragHandle } from './FormTableRow'
import { FormTableCell } from './FormTableCell'
import { EstimatesMaterialsFormTableStyled } from './styles'

type EditableTableProps = Parameters<typeof Table>[0]
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>

export interface EstimatesMaterialsFormTableProps {
  initialData?: MaterialDataType[] | void
  appliedAssembly?: MaterialDataType[] | void
  disableMarkup?: boolean
  inlineCreatedMaterials?: {
    [key: string]: { id: string; prices: { id: string; unit_amount: number }[] }
  }
  onMaterialsChange?: (newData?: any[], row?: MaterialDataType) => void
  onCreateNewMaterialClick?: (record: MaterialDataType) => void
  onAddAssemblyClick?: () => void
  onInputError?: (error: any, record: MaterialDataType) => void
}

const getMaterialSelectItemRenderComponent = (item: any) => {
  return (
    <MaterialSelectItemRender
      title={item.name}
      subtitle={item.description}
      globalCatalog={item.is_global}
    />
  )
}
const getLastItemMarkup = (items: any) => {
  if (!items || !items.length) return 0

  return items[items.length - 1]?.markup || 0
}

export const EstimatesMaterialsFormTable = ({
  initialData = [],
  appliedAssembly = [],
  disableMarkup,
  inlineCreatedMaterials,
  onMaterialsChange = () => {},
  onCreateNewMaterialClick = () => {},
  onAddAssemblyClick = () => {},
  onInputError = () => {},
}: EstimatesMaterialsFormTableProps) => {
  const [initialProductList, setInitialProductList] = React.useState<any>([])
  const [dataSource, setDataSource] =
    React.useState<MaterialDataType[]>(initialData)
  const [newRowKey, setNewRowKey] = React.useState<string | null>(null)

  React.useEffect(() => {
    setDataSource(initialData)
  }, [initialData]) // Dependency on initialData

  React.useEffect(() => {
    if (appliedAssembly && appliedAssembly.length) {
      setDataSource([...dataSource, ...appliedAssembly])
      onMaterialsChange([...dataSource, ...appliedAssembly])
    }
  }, [appliedAssembly]) // Dependency on appliedAssembly

  React.useEffect(() => {
    if (inlineCreatedMaterials && Object.keys(inlineCreatedMaterials).length) {
      const dataSourceCopy = [...dataSource]

      for (let key in inlineCreatedMaterials) {
        const foundIndex = dataSourceCopy.findIndex(
          (material) => material.key == key
        )
        const inlineCreatedMaterial = inlineCreatedMaterials[key]
        const inlineCreatedLatestPrice = inlineCreatedMaterial.prices?.[0]

        if (foundIndex > -1) {
          dataSourceCopy[foundIndex] = {
            ...dataSourceCopy[foundIndex],
            name: {
              label: dataSourceCopy[foundIndex]?.name?.label || '',
              value: inlineCreatedMaterial.id,
            },
            productId: inlineCreatedMaterial.id, // TODO bug if no priceId, backend doesn't save product properly
            priceId: inlineCreatedLatestPrice?.id,
            ...(inlineCreatedLatestPrice?.unit_amount && {
              price: inlineCreatedLatestPrice?.unit_amount,
            }),
            custom: false,
            recentlyCreated: true,
          }
        }
      }

      setDataSource(dataSourceCopy)
      onMaterialsChange(dataSourceCopy) // Trigger save to parent form
    }
  }, [inlineCreatedMaterials])

  const {
    data: { products = { data: [] } } = { products: { data: [] } },
    refetch: refetchProducts,
  } = useGetProductsQuery({
    variables: { input: { limit: 100, includeGlobal: true } },
    onCompleted: (data) => {
      setInitialProductList(data.products?.data)
    },
  })

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.key === active?.id
        )
        const overIndex = prevState.findIndex(
          (record) => record.key === over?.id
        )
        const newState = arrayMove(prevState, activeIndex, overIndex)
        onMaterialsChange(newState)
        return newState
      })
    }
  }

  const handleAdd = (
    type: string = 'good',
    custom?: boolean,
    index?: number
  ) => {
    const newKey = Math.random().toString(36)
    const newData: MaterialDataType = {
      key: newKey,
      quantity: 1,
      markup: getLastItemMarkup(dataSource),
      price: 0,
      total: 0,
      type,
      custom,
    }

    // Create a copy of the dataSource array
    const updatedDataSource = [...dataSource]

    // If index is specified and valid, splice the new item at that index
    if (index !== undefined && index >= 0 && index <= dataSource.length) {
      updatedDataSource.splice(index, 0, newData)
    } else {
      // Otherwise, add it to the end as before
      updatedDataSource.push(newData)
    }

    setDataSource(updatedDataSource)
    onMaterialsChange(updatedDataSource)

    // Set the newly added row key so it enters edit mode
    setNewRowKey(newKey) // Add this line

    // Clear the new row key after a short delay to avoid issues if user adds multiple rows quickly
    setTimeout(() => {
      setNewRowKey(null)
    }, 100)
  }

  const handleRmove = (key: number) => {
    const newData = dataSource.filter((item: any) => item.key !== key)
    setDataSource(newData)
    onMaterialsChange(newData, {
      key,
      quantity: 0,
      price: 0,
      markup: 0,
      total: 0,
    })
  }

  const handleSave = React.useCallback(
    (row: MaterialDataType) => {
      const newData = [...dataSource]
      const index = newData.findIndex((item) => row.key === item.key)

      const item = newData[index]
      newData.splice(index, 1, {
        ...item,
        ...row,
      })

      onMaterialsChange(newData, row)
      setDataSource(newData)
    },
    [dataSource, onMaterialsChange]
  )

  const handleFetchProducts = async (value: string): Promise<any> => {
    return refetchProducts({
      input: { search: value, limit: 100, includeGlobal: true },
    }).then(({ data }: any) => {
      return data?.products?.data?.map((item: any) => ({
        value: item.id,
        data: item,
        label: getMaterialSelectItemRenderComponent(item),
      }))
    })
  }
  const handleFormatOptionsList = React.useCallback(
    (data?: any) => {
      return initialProductList.map((item: any) => ({
        value: item.id,
        data: item,
        label: getMaterialSelectItemRenderComponent(item),
      }))
    },
    [initialProductList] // Only update if the initialProductList changes
  )
  const handleRemoveAll = () => {
    setDataSource([])
    onMaterialsChange([])
  }

  const handleReset = () => {
    Modal.confirm({
      title: `Remove All?`,
      content: 'Are you sure you want to remove all materials?',
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return handleRemoveAll()
      },
    })
  }

  const getInfoColumnElement = (record: MaterialDataType) => {
    const {
      key,
      type,
      custom,
      recentlyCreated,
      name,
      wastage_amount,
      wastage_percentage,
      total_coverage_rate,
      fixed_price,
      price,
      quantity,
      markup,
    } = record

    // Allow quick create button
    if (type === 'good' && custom && name?.label) {
      return (
        <Button
          type='text'
          shape='circle'
          size='middle'
          className='quick-add-button'
          onClick={(e) => {
            onCreateNewMaterialClick(record)
          }}
          icon={<PlusCircleOutlined />}
        />
      )
    } else if (wastage_amount && wastage_amount > 0) {
      return (
        <WastageIcon
          percent={wastage_percentage}
          amount={wastage_amount}
          coverageCapacity={total_coverage_rate}
        />
      )
    } else if (recentlyCreated) {
      return (
        <Tooltip title='Added to materials'>
          <CheckCircleOutlined
            style={{ color: theme.colors.success, fontSize: '16px' }}
          />
        </Tooltip>
      )
    } else if (fixed_price && !disableMarkup) {
      return (
        <FixedPriceProfitCostIcon
          cost={calculateLineItemTotal(price, quantity)}
          profit={calculateLineItemProfit(price, quantity, markup, fixed_price)}
        />
      )
    }
  }

  const isSectionOrAssembly = (record: MaterialDataType) => {
    return record.type === 'section' || record.type === 'assembly'
  }
  const isSetCostItem = (record: MaterialDataType, columnMatch: boolean) => {
    return record.fixed_price && columnMatch
  }

  const dropdownButtonItems = [
    {
      key: '1',
      icon: <PlusSquare size={14} />,
      label: 'Add Custom Material',
      onClick: () => {
        handleAdd('good', true)
      },
      'data-testid': 'list-add-custom-material-button',
    },
    {
      key: '2',
      icon: <ToolOutlined size={20} />,
      label: 'Add Custom Labor',
      onClick: () => {
        handleAdd('service', true)
      },
      'data-testid': 'list-add-labor-button',
    },
    {
      key: '3',
      icon: <FormOutlined size={20} />,
      label: 'Add Section',
      onClick: () => {
        handleAdd('section')
      },
      'data-testid': 'list-add-section-button',
    },
    // {
    //   key: '4',
    //   icon: <Layers size={14} />,
    //   label: 'Add Assembly',
    //   onClick: onAddAssemblyClick,
    //   'data-testid': 'list-add-template-button',
    // },
  ]

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean
    inputType?: 'text' | 'percent' | 'price' | 'quantity' | 'markup' | 'product'
    dataIndex?: string
  })[] = [
    {
      key: 'sort',
      dataIndex: 'sort',
      align: 'center',
      width: '5%',
      render: (_, { childNode }) => {
        return !childNode && <FormTableRowDragHandle />
      },
    },
    // Table.EXPAND_COLUMN,
    {
      title: 'Item',
      width: '35%',
      dataIndex: 'name',
      editable: true,
      className: 'editable-cell',
      ellipsis: true,
      inputType: 'product',
      render: (_, data) => {
        const renderItemCell = () => {
          // Case 1: Empty state - no item selected yet
          if (!data.name && !data._name_string && !data.name?.label) {
            return (
              <Typography.Text
                type='secondary'
                style={{ cursor: 'pointer', fontStyle: 'italic' }}
              >
                Click here to input an item
              </Typography.Text>
            )
          }

          // Case 2: Section header or custom item
          if (data.type === 'section' || data.custom) {
            return (
              <Typography.Text title={data.name?.label} ellipsis>
                {data.name?.label}
              </Typography.Text>
            )
          }

          // Case 3: Item with string name (legacy format)
          if (data._name_string) {
            const fullTitle = data.description
              ? `${data._name_string} - ${data.description}`
              : data._name_string

            return (
              <Typography.Text title={fullTitle} ellipsis>
                {data._name_string}
                {data.description && (
                  <Typography.Text type='secondary'>
                    {` - ${data.description}`}
                  </Typography.Text>
                )}
              </Typography.Text>
            )
          }

          // Case 4: Item with label object (current format)
          return <Typography.Text ellipsis>{data.name?.label}</Typography.Text>
        }

        return (
          <div className='item-cell-wrapper'>
            {renderItemCell()}

            {data.type === 'service' && (
              <div className='service-badge-container'>
                <ServiceItemBadge />
              </div>
            )}
          </div>
        )
      },
    },
    {
      title: null,
      align: 'center',
      width: '5%',
      dataIndex: 'infoColumn',
      render: (_, record: any) => getInfoColumnElement(record),
    },
    {
      title: 'Quantity',
      width: '10%',
      dataIndex: 'quantity',
      editable: true,
      className: 'editable-cell',
      inputType: 'quantity',
    },
    {
      title: 'Price',
      width: '15%',
      dataIndex: 'price',
      editable: true,
      className: 'editable-cell',
      inputType: 'price',
      render: (_, { price, fixed_price, ...rest }) => {
        if (disableMarkup) {
          return <span>{formatMoney(price)}</span>
        } else {
          return <span>{formatMoney(fixed_price || price)}</span>
        }
      },
    },
    {
      title: 'Markup',
      width: '10%',
      dataIndex: 'markup',
      editable: !disableMarkup,
      className: 'editable-cell',
      inputType: 'markup',
      render: (_, { markup, fixed_price }) => {
        if (fixed_price || disableMarkup) {
          return null
        } else {
          return <span>{markup}%</span>
        }
      },
    },
    {
      title: 'Total',
      width: '15%',
      dataIndex: 'total',
      render: (_, { price, fixed_price, quantity, markup }) => {
        const markupAmount = disableMarkup ? 0 : markup

        if (fixed_price && !disableMarkup) {
          return (
            <span className='cell-padding-bottom'>
              {/* Default rate should always be used over the price for showing line total */}
              {formatMoney(
                calculateLineItemTotal(fixed_price, quantity, markupAmount)
              )}
            </span>
          )
        } else {
          return (
            <span className='cell-padding-bottom'>
              {/* Default rate should always be used over the price for showing line total */}
              {formatMoney(
                calculateLineItemTotal(price, quantity, markupAmount)
              )}
            </span>
          )
        }
      },
    },
    {
      title: () =>
        dataSource.length > 0 ? (
          <Button
            type='text'
            icon={<CloseSquareOutlined />}
            onClick={handleReset}
          />
        ) : (
          <div style={{ width: '32px', height: '32px' }} />
        ),
      key: 'action',
      dataIndex: 'action',
      align: 'right',
      width: 90,
      render: (_, record, index) => (
        <Space>
          {record.type === 'section' && (
            <Tooltip title='Add item to section'>
              <Button
                type='text'
                icon={<PlusCircleOutlined />}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  handleAdd('good', false, index + 1)
                }}
              />
            </Tooltip>
          )}
          <Button
            type='text'
            icon={<CloseOutlined />}
            onClick={() => handleRmove(record.key)}
          />
        </Space>
      ),
    },
  ]

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return {
        ...col,
        onCell: (record: MaterialDataType) => ({
          // TODO Refactor
          hidden:
            isSectionOrAssembly(record) &&
            col.dataIndex != 'name' &&
            col.dataIndex != 'sort' &&
            col.dataIndex != 'action' &&
            col.dataIndex != 'expand',
        }),
      }
    }

    return {
      ...col,
      onCell: (record: MaterialDataType) => {
        return {
          colSpan:
            isSectionOrAssembly(record) && col.dataIndex === 'name' ? 6 : 1,
          hidden:
            isSectionOrAssembly(record) &&
            col.dataIndex != 'name' &&
            col.dataIndex != 'expand',
          record,
          editable:
            col.editable &&
            !isSetCostItem(
              record,
              col.dataIndex == 'price' || col.dataIndex == 'markup'
            ),
          inputType: col.inputType,
          dataIndex: col.dataIndex,
          title: col.title,
          formattedOptionsList: handleFormatOptionsList(products),
          handleSave,
          handleFetchProducts,
          onCreateNewMaterialClick,
          onInputError,
        }
      },
    }
  })

  return (
    <EstimatesMaterialsFormTableStyled>
      <EditingContext.Provider value={{ newRowKey, setNewRowKey }}>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={dataSource.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              rowKey='key'
              onRow={(record, index) => {
                return {
                  className: record.type === 'section' ? 'section-row' : '',
                }
              }}
              components={{
                body: { row: FormTableRow, cell: FormTableCell },
              }}
              columns={columns as ColumnTypes}
              dataSource={dataSource}
              pagination={false}
              size='small'
              data-testid='material-list-builder-table'
              // virtual
              // scroll={{ y: 1500 }}
              // expandable={{
              //   defaultExpandAllRows: true,
              //   expandIconColumnIndex: 1,
              //   expandIcon: ({ expandable, expanded, onExpand, record }) => {
              //     if (!expandable) return

              //     if (expanded) {
              //       return <DownOutlined onClick={(e) => onExpand(record, e)} />
              //     } else {
              //       return <RightOutlined onClick={(e) => onExpand(record, e)} />
              //     }
              //   },
              // }}
            />

            <ListButtonBar
              onAddAssemblyClick={onAddAssemblyClick}
              onAddClick={handleAdd}
              dropdownButtonItems={dropdownButtonItems}
            />
          </SortableContext>
        </DndContext>
      </EditingContext.Provider>
    </EstimatesMaterialsFormTableStyled>
  )
}

EstimatesMaterialsFormTable.displayName = 'EstimatesMaterialsFormTable'
