// src/components/ClerkAuthComponents.tsx
import React, { useEffect } from 'react'
import { SignIn, SignUp } from '@clerk/clerk-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { UTMManager } from '../../utils/utmManager'

export const ClerkSignIn: React.FC = () => {
  const location = useLocation()

  useEffect(() => {
    // Capture UTM parameters when component mounts
    UTMManager.captureParams()
  }, [])

  // Create auth callback URL with UTM parameters
  const redirectUrl = UTMManager.appendParamsToUrl(
    `${window.location.origin}/auth-callback`
  )

  return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        top: '25%',
        marginLeft: '-200px',
      }}
    >
      <SignIn routing='path' path='/sign-in' redirectUrl={redirectUrl} />
    </div>
  )
}

ClerkSignIn.displayName = 'ClerkSignIn'

export const ClerkSignUp: React.FC = () => {
  useEffect(() => {
    // Capture UTM parameters when component mounts
    UTMManager.captureParams()
  }, [])

  // Create auth callback URL with UTM parameters
  const redirectUrl = UTMManager.appendParamsToUrl(
    `${window.location.origin}/auth-callback`
  )

  return <SignUp routing='path' path='/sign-up' redirectUrl={redirectUrl} />
}

ClerkSignUp.displayName = 'ClerkSignUp'
