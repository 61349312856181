import {
  Col,
  Row,
  Spin,
  Layout,
  Space,
  Button,
  notification,
  message,
} from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import {
  EstimatesForm,
  EstimatesFormProps,
} from '../../components/EstimatesForm'
import {
  SortOrderInput,
  EstimateStatus,
  EstimateSortFieldInput,
  useGetJobLazyQuery,
  useGetEstimatesQuery,
  useGetEstimateLazyQuery,
  useGetDraftEstimateLazyQuery,
  useDeleteEstimateDraftMutation,
  useUpdateMeMutation,
} from '../../graphql/_generated-hooks'

import { DetailViewLayout } from '../shared/DetailViewLayout'
import { ViewHeader } from '../shared/ViewHeader'

import { PageTitle } from '../../components/PageTitle'
import { EstimatesFormViewStyled } from './styles'
import { ONBOARDING_ESTIMATE } from '../../config/default'

export interface EstimatesFormViewProps {
  me?: any
  mobile?: boolean
  navigationCollapsed?: boolean
}

export const EstimatesFormView = (props: EstimatesFormViewProps) => {
  const { me, mobile, ...rest } = props
  const {
    default_currency,
    default_taxes,
    default_additional_content,
    default_labor_label,
    default_labor_rate,
  } = me?.organization?.settings || {}

  let { id } = useParams()
  const [messageApi, messageContextHolder] = message.useMessage()
  const [notificationApi, notifcationContextHolder] =
    notification.useNotification({
      maxCount: 1,
    })
  const [searchParams, setSearchParams] = useSearchParams()
  const [isSaving, setIsSaving] = React.useState(false)
  const [isOnboarding, setIsOnboarding] = React.useState(false)
  const [estimateToEdit, setEstimateToEdit] = React.useState<any>({
    additional_content: default_additional_content,
    labor_label: default_labor_label,
    labor_rate: default_labor_rate,
    taxes: default_taxes,
    currency: default_currency,
  })
  const [lastEstimateNumber, setLatestEstimateNumber] = React.useState<
    string | null
  >('')
  const [getEstimate, { loading: loadingEstimate }] = useGetEstimateLazyQuery()
  const [getEstimateDraft, { loading: loadingDraft }] =
    useGetDraftEstimateLazyQuery({
      fetchPolicy: 'network-only',
    })
  const [getJob, { loading: loadingJob }] = useGetJobLazyQuery()
  const [deleteEstimateDraft, { loading: isDeletingDraftEstimate }] =
    useDeleteEstimateDraftMutation()
  const [updateMeMutation] = useUpdateMeMutation()

  // TODO cache this number
  if (!id) {
    const {
      data: lastEstimate,
      loading: isLoadingLatestEstimate,
      refetch: refetchLatestEstimate,
      updateQuery: updateLatestEstimate,
    } = useGetEstimatesQuery({
      variables: {
        input: {
          limit: 1,
          sort: {
            field: EstimateSortFieldInput.CreatedAt,
            order: SortOrderInput.Desc,
          },
          status: EstimateStatus.Open,
        },
      },
      onCompleted({ estimates }) {
        if (estimates && estimates.data?.length) {
          setLatestEstimateNumber(estimates.data[0]?.number || '')
        }
      },
    })
  }

  React.useEffect(() => {
    if (id) {
      getEstimate({
        variables: { id },
        onCompleted: ({ estimate }) => {
          if (estimate) {
            setEstimateToEdit(estimate)
          }

          if (estimate && estimate.draftId) {
            getEstimateDraft({
              variables: {
                id: estimate.draftId,
              },
              onCompleted({ draftEstimate }) {
                if (draftEstimate && draftEstimate.id) {
                  openNotification(draftEstimate)
                }
              },
            })
          }
        },
      })
    } else if (searchParams.get('jobId')) {
      // Get Job
      getJob({
        variables: { id: searchParams.get('jobId') || '' },
        onCompleted({ job }) {
          if (job && job.customers?.length) {
            setEstimateToEdit({
              job,
            })
          }
        },
      })
    } else {
      getEstimateDraft({
        onCompleted({ draftEstimate }) {
          if (draftEstimate && draftEstimate.id) {
            openNotification(draftEstimate)
          }
        },
      })
    }

    // If onboarding, overwrite everything
    if (searchParams && searchParams.get('onboarding')) {
      setEstimateToEdit({
        ...ONBOARDING_ESTIMATE,
      })
      setIsOnboarding(true)
    } else {
      setIsOnboarding(false)
    }
  }, [id])

  const onFormSubmit: EstimatesFormProps['onSubmit'] = ({ number }) => {
    notificationApi.destroy()
    setIsSaving(true)
  }
  const onFormCancel: EstimatesFormProps['onCancel'] = () => {
    notificationApi.destroy()
  }

  const handleApplyDraft = (draftEstimate: any, destroyNotification: any) => {
    setEstimateToEdit(draftEstimate)
    destroyNotification()
  }
  const handleDeleteDraft = (draftEstimate: any, destroyNotification: any) => {
    return deleteEstimateDraft({
      variables: {
        id: draftEstimate.id,
      },
      onCompleted({ deleteEstimateDraft }: any = {}) {
        destroyNotification()
      },
      onError(error, clientOptions) {
        messageApi.error('An error occurred. Please try again.')
      },
    })
  }
  const handleOnOnboardingComplete = () => {
    searchParams.delete('onboarding')
    setSearchParams(searchParams)
    setIsOnboarding(false)
    updateMeMutation({
      variables: {
        input: {
          onboarding: [
            ...(me?.onboarding || []),
            {
              step: 'welcome',
              completed: true,
            },
          ],
        },
      },
    })
  }
  const openNotification = (draftEstimate: any) => {
    if (mobile) return

    notificationApi.open({
      message: 'Recovered unsaved data',
      description:
        'You have unsaved estimate data from a previous session. Would you like to restore it?',
      duration: 0,
      onClose: () => {
        handleDeleteDraft(draftEstimate, notificationApi.destroy)
      },
      btn: (
        <Space>
          <Button
            type='link'
            size='small'
            onClick={() => {
              handleDeleteDraft(draftEstimate, notificationApi.destroy)
            }}
            loading={isDeletingDraftEstimate}
            danger
            data-testid='delete-draft-notification-button'
          >
            Delete
          </Button>
          <Button
            type='primary'
            size='small'
            onClick={() => {
              handleApplyDraft(draftEstimate, notificationApi.destroy)
            }}
            data-testid='restore-draft-notification-button'
          >
            Restore
          </Button>
        </Space>
      ),
    })
  }

  return (
    <EstimatesFormViewStyled>
      {messageContextHolder}
      {notifcationContextHolder}
      <DetailViewLayout
        header={
          <ViewHeader
            showLogo={mobile}
            pageTitle={
              <PageTitle
                title={id ? 'Edit Estimate' : 'New Estimate'}
                subtitle='Manage estimates for your customers.'
              />
            }
          />
        }
      >
        <Spin spinning={loadingEstimate || loadingDraft || loadingJob}>
          {id && estimateToEdit && (
            <EstimatesForm
              isSaving={isSaving}
              parentId={id}
              initialValues={estimateToEdit}
              edit={true}
              mobile={mobile}
              onSubmit={onFormSubmit}
              onCancel={onFormCancel}
              {...rest}
            />
          )}

          {!id && (
            <EstimatesForm
              initialValues={estimateToEdit || {}}
              lastEstimateNumber={lastEstimateNumber}
              onboarding={isOnboarding}
              mobile={mobile}
              onOnboardingComplete={handleOnOnboardingComplete}
              onSubmit={onFormSubmit}
              onCancel={onFormCancel}
              {...rest}
            />
          )}
        </Spin>
      </DetailViewLayout>
    </EstimatesFormViewStyled>
  )
}

EstimatesFormView.displayName = 'EstimatesFormView'
